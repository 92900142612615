import React, { useState } from "react";
import SingleInput from "../../general/core/SingleInput";
import FormNotification from "../../general/core/FormNotification";
import { CSSTransition } from 'react-transition-group';

const SendCopyForm = ({ updateForm, handleSubmitEmail, email, paymentLinkData, emailInputWrong, emailSent }) => {
  const [emailBtnDisabled, setEmailBtnDisabled] = useState(false);
  const [notifyEmailSent] = useState(false);
  const [notifyLinkCopied, setNotifyLinkCopied] = useState(false);
  const [emailBtnClicked, setEmailBtnClicked] = useState(false);

  const errorMsgEmail = "Please enter email in correct format";
  //let _isMounted = false;

  /*const handleSelectDuration = event => {
    updateForm(event.name, event.value);
  };*/

  const disableNotification = () => {
    setNotifyLinkCopied(false);
  };

  const sendEmail = e => {
    handleSubmitEmail(e);
    setEmailBtnDisabled(true);
    setEmailBtnClicked(true);
  };

  const enableCloseBtn = () => {
    updateForm();
  };

  return (
    <div>
      <div style={{height:"90px", textAlign:"center", filter:"invert(52%) sepia(97%) saturate(366%) hue-rotate(109deg) brightness(91%) contrast(98%)"}}>
        <img src="/img/icons/icons8-check-circle-150.svg" height="90px" alt="." />
      </div>

      <div style={{textAlign:"center", fontSize:"24px", fontWeight:"bold", color:"rgb(71, 85, 105)"}}>
        Your link has been <br />
        successfully created!
      </div>

      { emailSent ? 
        <div>
          <div style={{marginTop: "32px", fontWeight: "bold", color: "rgb(71, 85, 105)", marginBottom: "20px"}}>
            Customer email sent to:
          </div>
          <div style={{fontSize: "16px", paddingLeft: "10px", color: "rgb(148, 163, 184)", marginBottom:"32px"}} data-matomo-mask>
            {email}
          </div>
        </div> :
        <SingleInput
          id="merchantIDSelection"
          name="email"
          width="100%"
          label="Email to"
          placeholder="Customer email"
          autoComplete="off"
          marginTop="32px"
          disabled={true}
          diplayButton={true}
          buttonText="Send Link"
          //value={this.props.paymentLinkData["email"]}
          value={email}
          buttonOnClick={e => { sendEmail(e); enableCloseBtn(); }}
          buttonDisabled={emailBtnDisabled && !emailInputWrong}
          buttonLoading={emailBtnClicked}
          errorMsg={errorMsgEmail}
          showErrorMsg={emailInputWrong}
          data-matomo-mask
        />
      }

      { notifyEmailSent ?
        <FormNotification 
          text="Email sent!"
          top="399px"
          right="20px"/> :
        null
      }

      <SingleInput
        id="selectCurrency"
        label="Copy + Share"
        placeholder=""
        marginRight="0px"
        marginLeft="0px"
        marginTop="4px"
        disabled={true}
        backgroundColor="#eff0f1"
        value={paymentLinkData["paymentLink"]}
        diplayButton={true}
        buttonText="Copy Link"
        buttonOnClick={() => {
          enableCloseBtn();
          setNotifyLinkCopied(true);
        }}
        shouldCopyToClipboard={true}
      />

      <CSSTransition
        in={notifyLinkCopied}
        timeout={300}
        classNames="plDetailsData"
        unmountOnExit>
          <FormNotification 
          text="Link copied!"
          top="494px"
          right="20px"
          callback={disableNotification}/>
      </CSSTransition>
    </div>
  );
};

export default SendCopyForm;