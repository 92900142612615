import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CONFIRM from "../../general/core/Confirmation";
import ALERT from "../../general/core/Alert";
import { getTransDetails } from "../../../actions/transLogAction";
import { getPermissions } from "../../../actions/dashboardAction";
import { interfacePaymentCall } from "../../../actions/transLogAction";
import Popup from "reactjs-popup";
import { ScaleLoader } from "react-spinners";
import * as helper from "../../general/core/helper";
import { Row, Col } from 'react-bootstrap';
import SingleInput from "../../general/core/SingleInput";
import TextAreaInput from "../../general/core/TextAreaInput";

class TransactionDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showConfirm: false,
      fieldConfirm: "",
      getTransDetails_response: {},
      amount: "",
      bitcoinDestinationAddr: "",
      inputNotValid: false,
      walletAddressNotValid: false,
      transactionFailed: false,
      interfacePayment_response: {},
      can_cancel: false,
      can_cft: false,
      can_refund: false,
      can_manual_refund: false,
      can_capture: false,
      refund_button_row: "",
      errormessage: "",
      transactionID: parseInt(window.location.search.substring(1)),
      merchantID: "",
      refundAmount: "",
      externalID: "",
      refundComment: "",
      cftExternalID: "",
      cftComment: "",
      confirmTitle: "",
      cft_button_row: "",
      user_permissions: {},
      cryptocurrency: ""
    };
  }

  testConfirmation = e => {
    e.preventDefault();
    this.setState({
      showConfirm: true,
      fieldConfirm: e.target.innerText,
      confirmTitle: e.target.innerText,
    });
  };

  refundConfirmation = e => {
    e.preventDefault();
    this.setState({
      showConfirm: true,
      fieldConfirm: 'Refund ' + e.target.innerText,
      confirmTitle: e.target.innerText + ' the Refund',
      refund_button_row: e.target.value,
    });
  };

  cftConfirmation = e => {
    e.preventDefault();
    this.setState({
      showConfirm: true,
      fieldConfirm: 'CFT ' + e.target.innerText,
      confirmTitle: e.target.innerText + ' the CFT',
      cft_button_row: e.target.value,
    });
  };


  checkValidity(value) {
    if (!isNaN(value)) {
      /* If the value is a number */
      if (value % 1 !== 0) {
        /* If the value is a float */
        let fractional_part_len = value.toString().split(".")[1].length; /* determining number of digits in fractional part of value */
       
        let integer_part_len = value.toString().split(".")[0].length; /* determining number of digits in integer part of value */
        if (fractional_part_len === 2 && integer_part_len >= 1 && value >= 0) {
          return true;
        } 
        else {
          return false;
        }
      } else {
        /* If the value is a integer */
        if (value <= 0) // determining if the value is a negative number
        {
          return false;
        }
        else {
          return true;
        }       
      }
    } 
    else {
      return false;
    }
  }
 
 	checkWalletAddressFormat(value) {
 		//const regex = new RegExp('^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$');
 		const regex = new RegExp('[a-zA-Z0-9]');
 		return regex.test(value);
 	}

  submitAmount(event, actionType, refund_row_num, isBitcoin = false) {
    event.preventDefault();
    
    // Checking if BitCoin address is valid or alert should be displayed.
    if (isBitcoin && !this.checkWalletAddressFormat(this.state.bitcoinDestinationAddr)) {
    	this.setState({
        walletAddressNotValid: true,
      });
    }

    else if ((actionType === "CFT" || actionType === "Edit") && (!this.state.amount || !this.checkValidity(this.state.amount))) {

      this.setState({
        inputNotValid: true,
      });

    }

    // We don't need to submit refund amount for cryptocurrency refund. 
    // So we don't need to call this.checkValidity().
    else if( actionType === "Refund"  && isBitcoin && !this.state.refundAmount )
    {
      this.setState({
        inputNotValid: true,
      });
    }

    else if ((actionType === "Refund" || actionType === "Manual Refund" ) && !isBitcoin && (!this.state.refundAmount || !this.checkValidity(this.state.refundAmount))) {

      this.setState({
        inputNotValid: true,
      });

    } 
    
    else {

      if (actionType === "Edit") {
        this.setState({
          showConfirm: true,
          fieldConfirm: 'Refund ' + actionType,
          confirmTitle: actionType + ' the Refund',
          inputNotValid: false,
          walletAddressNotValid: false,
          refund_button_row: refund_row_num,
        });
      }

      else {
        this.setState({
          showConfirm: true,
          fieldConfirm: actionType,
          confirmTitle: actionType,
          inputNotValid: false,
          walletAddressNotValid: false,
        });
      }
    }
  }

  setActionFlags() {   // Setting flags for actions

    /* ##########################################################################################
      Setting flags according to the 'actions' field in response from get_transaction_details api. 
      #############################################################################################*/

    if (this.state.getTransDetails_response.actions && this.state.getTransDetails_response.actions.can_capture === 1) {
      this.setState({
        can_capture: true
      });
    }
    else {
      this.setState({
        can_capture: false
      });
    }

    if (this.state.getTransDetails_response.actions && this.state.getTransDetails_response.actions.can_cancel === 1) {
      this.setState({
        can_cancel: true
      });
    }
    else {
      this.setState({
        can_cancel: false
      });
    }
    if (this.state.getTransDetails_response.actions && this.state.getTransDetails_response.actions.can_cft === 1) {
      this.setState({
        can_cft: true
      });
    }
    else {
      this.setState({
        can_cft: false
      });
    }

    if (this.state.getTransDetails_response.actions && this.state.getTransDetails_response.actions.can_refund === 1) {
      this.setState({
        can_refund: true
      });
    }
    else {
      this.setState({
        can_refund: false
      });
    }

    if (this.state.getTransDetails_response.actions && this.state.getTransDetails_response.actions.can_manual_refund === 1) {
      this.setState({
        can_manual_refund: true
      });
    }
    else {
      this.setState({
        can_manual_refund: false
      });
    }

  }

  setRefundAmount() {

    let refund_amount = 0;

    if (this.state.getTransDetails_response?.transaction_history) {
      for (const transaction of this.state.getTransDetails_response
        .transaction_history) {
        const amount = parseFloat(transaction.amount) || 0;

        if (transaction.type === "Capture") {
          refund_amount += amount;
        } else if (
          transaction.type === "Refund" &&
          transaction.status !== "Failed" &&
          transaction.status !== "Aborted"
        ) {
          refund_amount -= amount;
        }
      }
    }

    // Related CFTs should also be considered for refund amount - see #435314
    if (this.state.getTransDetails_response?.related_cft_transaction_history) {
      for (const transaction of this.state.getTransDetails_response
        .related_cft_transaction_history) {
        const amount = parseFloat(transaction.amount) || 0;

        if (transaction.type === "CFT" && transaction.status !== "Failed") {
          refund_amount -= amount;
        }
      }
    }

    // taking 2 digits after decimal point
    const refundAmountStr = refund_amount.toFixed(2)

    this.setState({
      refundAmount: refundAmountStr
    });

  }

  async sendTransDetailsReq (transactionId) {
    let requestBody = {
      transactionId: transactionId, 
    };
    const response = await this.props.getTransDetails(requestBody);
    const permissions = await this.props.getPermissions();
    if(response.payload && Object.keys(response.payload).length !== 0) {
      if(response.payload.order_details) {
        this.setState({
          getTransDetails_response: response.payload,
          merchantID: response.payload.order_details.merchant_id,
          user_permissions: permissions.payload.user_permissions,
        });
      }
      else {
        this.setState({
          getTransDetails_response: response.payload,
          user_permissions: permissions.payload.user_permissions
        });
      }

      if ( response.payload.additional_payment_information && response.payload.additional_payment_information.cryptocurrency ) {
        this.setState({
          cryptocurrency: response.payload.additional_payment_information.cryptocurrency
        });
      }
    }

    this.setActionFlags();  // Setting flags for actions
    this.setRefundAmount(); // Setting refund amount
  }

  componentDidMount() {
    
    this.sendTransDetailsReq(this.state.transactionID);

  }

  async apiCall(actionType, currency = "", refund_or_cft_amount = "", paymentmethod_id = "", transaction_history_id = "", cft_transaction_id = "") {
    
    /* ####################
     Capture, Cancel, Refund, Refund-edit, Refund-cencel, CFT or CFT-cancel api is called
    #################### */
  
    let transactionid = this.state.transactionID;
    let merchantid = this.state.merchantID;

    let requestBody = {}

    if (actionType === "refund_edit") {
      requestBody = {
        transactionid: transactionid,
        action_type: actionType,
        merchantid: merchantid,
        transaction_history_id : transaction_history_id,
        amount: this.state.amount,
        currency: currency
      };
    }

    else if (actionType === "refund_cancel") {
      requestBody = {
        transactionid: transactionid,
        action_type: actionType,
        merchantid: merchantid,
        transaction_history_id : transaction_history_id,
        amount: refund_or_cft_amount,
        currency: currency
      };
    }

    else if (actionType === "refund") {
      requestBody = {
        transactionid: transactionid,
        action_type: actionType,
        merchantid: merchantid,
        amount: this.state.refundAmount,
        refund_comment: this.state.refundComment,
        cft_comment: this.state.refundComment,
        currency: currency,
        external_id: this.state.externalID
      };

      if (paymentmethod_id === 18) {
        requestBody = {
          transactionid: transactionid,
          action_type: "refund_cryptocurrency",
          merchantid: merchantid,
          cryptocurrency_destination_addr: this.state.bitcoinDestinationAddr
        };
      }
    }

    else if (actionType === "manual_refund" ) {
        requestBody = {
          transactionid: transactionid,
          action_type: "refund",
          merchantid: merchantid,
          amount: this.state.refundAmount,
          refund_comment: this.state.refundComment,
          currency: currency,
          external_id: this.state.externalID,
          manual_refund: "137",
          paymentmethodid: paymentmethod_id
        }; 
    }
    
    else if (actionType === "capture" || actionType === "cancel") {
      requestBody = {
        transactionid: transactionid,
        action_type: actionType,
        merchantid: merchantid,
      };
    }

    else if (actionType === "cft") {
      requestBody = {
        transactionid: transactionid,
        action_type: actionType,
        merchantid: merchantid,
        amount: this.state.amount,
        currency: currency,
        payment_method: paymentmethod_id,
        cft_comment: this.state.cftComment,
        external_id: this.state.cftExternalID
      };
     
     	if ( paymentmethod_id === 18 && !this.state.cryptocurrency ) {
     		requestBody["bitcoin_destination_addr"] = this.state.bitcoinDestinationAddr;
     	}

      if (paymentmethod_id === 18 && this.state.cryptocurrency ) {
        requestBody["cryptocurrency_destination_addr"] = this.state.bitcoinDestinationAddr;
        requestBody["cryptocurrency"] = this.state.cryptocurrency;
      }
    }

    else if (actionType === "cft_cancel") {

      if (cft_transaction_id)
      {
        requestBody = {
          transactionid: cft_transaction_id,
          action_type: actionType,
          merchantid: merchantid,
          amount: refund_or_cft_amount,
        };
      }
      else
      {
        requestBody = {
          transactionid: transactionid,
          action_type: actionType,
          merchantid: merchantid,
          amount: refund_or_cft_amount,
        };
      }
      
    }

    else {
      requestBody = {
        transactionid: transactionid,
        action_type: actionType,
        merchantid: merchantid,
      };
    }
    const response = await this.props.interfacePaymentCall(requestBody);

    if (response.payload && response.payload.data) {
      this.setState({
        interfacePayment_response: response.payload.data.result,
      });

      let error_message = this.state.interfacePayment_response.errormessage.replace(/%20/g, ' ').replace(/%2F/g, '/').replace(/%3A/g, ' ');

      if(this.state.interfacePayment_response.status === "0" ) {
        this.setState({
          errormessage: 'Successful!',
          transactionFailed: true,
        });
      }
      else {
        this.setState({
          errormessage: error_message,
          transactionFailed: true,
        });
      }

    }

    this.setState({
      amount: ""
    });

    /* ####################
    getTransDetails is called again to update transaction history 
    #################### */
  
    this.sendTransDetailsReq(transactionid);
  
  }

  onValueChange = e => {
    e.preventDefault();

    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  createPopup = (actionType, refund_row_num = "") => {
    const PopupContentStyle = {
      width: "60vw",
      border: "none",
      backgroundColor: "#F4F6FB",
      borderRadius: "3px",
      padding: "20px"
    };

    const PopupContentStyle2 = {
      width: "60vw",
      border: "none",
      backgroundColor: "#F8FAFC",
      borderRadius: "3px",
      padding:"0px",
      maxWidth:"500px",
    };
   
   	const cancelBtnStyle = {
   		borderColor: "#77A3F6",
   		borderRadius: "10px",
   		padding: "7px 30px",
   		borderWidth: "2px",
   		color: "#1E6AF6",
   		backgroundColor: "#F4F6FB",
   		fontWeight: "bold"
   	};
   
   	const submitBtnStyle = {
   		borderColor: "#1E6AF6",
   		borderRadius: "10px",
   		padding: "7px 30px",
   		backgroundColor: "#1E6AF6",
   		color: "white",
   		fontWeight: "bold"
   	};
		
    return (
    	(this.state.getTransDetails_response.transaction_history && 
    	this.state.getTransDetails_response.transaction_history[0].paymentMethodId === 18 && actionType === "CFT") ? (
    		<Popup 
	        trigger={<button disabled={this.state.user_permissions.allow_user_bitcoin_payout === 0} className="btn btn-primary" > <b>{actionType}</b> </button>}
	        modal 
	        closeOnDocumentClick 
	        contentStyle={PopupContentStyle}>
	          {close => (
	            <div>
	              <div style={{textAlign:"left"}}>
                	<label style={{fontSize:'15px', paddingTop:'5px'}}> {actionType} </label>
	              </div>
								
								<Row>
		            	<Col xs={4} md={4} style={{paddingTop: "20px"}}>
										<div style={{textAlign:"left"}}>
			                <label style={{ fontSize: "12px", fontWeight: "normal" }}>
			                  Amount *
			                </label>
		                </div>
		                
		                <div style={{width:"100%", textAlign:"left"}}>
		                  <input
		                  id="amount"
		                  onChange={this.onValueChange}
		                  name="amount"
		                  value={this.state.amount}
		                  style={{width:"100%", height:"35px", borderRadius:"5px", borderWidth:"1px"}}
		                  required/>
	                  </div>
		            	</Col>
		            	
		            	<Col xs={8} md={8} style={{paddingTop: "20px"}}>
										<div style={{textAlign:"left"}}>
			                <label style={{ fontSize: "12px", fontWeight: "normal" }}>
			                    Destination Address *
			                </label>
		                </div>
		                
		                <div style={{width:"100%", textAlign:"left"}}>
		                  <input
		                  id="bitcoinDestinationAddr"
		                  onChange={this.onValueChange}
		                  name="bitcoinDestinationAddr"
		                  value={this.state.bitcoinDestinationAddr}
		                  style={{width:"100%", height:"35px", borderRadius:"5px", borderWidth:"1px"}}
		                  required/>
	                  </div>
		            	</Col>
	            	</Row>
	
		            <Row style={{ textAlign:"left", margin: "0px" }}>
                	<label style={{ color: "#F2AA6B", fontSize: "12px", fontWeight: "normal" }}>* All fields are mandatory</label>
		            </Row>
	
		            <Row
	              style={{
	                paddingTop: "40px",
	                textAlign: "right",
	                margin: "0px"
	              }}>
	             
	             		<button
	                className="btn"
	                style={cancelBtnStyle}
	                type="button"
	                onClick={() => {close();}}>
	                	{" "}Cancel{" "}
		              </button>
		              
		              &nbsp; &nbsp;
		              
		              <button
	                className="btn"
	                style={submitBtnStyle}
	                type="button"
	                onClick={e => {
	                  this.submitAmount(e, actionType, refund_row_num, true);
	                  close();
	                }}>
		                {" "}Submit{" "}
		              </button>
		              
		            </Row>
      				</div>
	        )}
	      </Popup>
   		) : (    
          // This section is for refunding cryptocurrencies like bitcoin

          this.state.getTransDetails_response.transaction_history && 
          this.state.getTransDetails_response.transaction_history[0].paymentMethodId === 18 && actionType === "Refund") ? (
            <Popup 

              trigger={<button className="btn btn-primary" > <b>{actionType}</b> </button>} 
              modal 
              closeOnDocumentClick 
              contentStyle={PopupContentStyle}>
                {close => (
                  <div>

                    <div style={{backgroundColor: 'rgb(52, 129, 165)', textAlign:'center'}}>
                        {<label style={{color:'white', fontSize:'15px', paddingTop:'5px'}}> {actionType} </label>}
                    </div>
      
                    <div  
                      style={{
                          paddingTop: "20px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          textAlign: "center",
                        }}>
        
                        <label style={{ color: "rgb(10, 103, 156)", fontSize: "15px" }}>
                          Destination Address *
                        </label>
        
                        &nbsp; &nbsp;
        
                        <input
                        id="bitcoinDestinationAddr"
                        onChange={this.onValueChange}
                        name="bitcoinDestinationAddr"
                        value={this.state.bitcoinDestinationAddr}
                        style={{width:"100%", height:"35px", borderRadius:"5px", borderWidth:"1px"}}
                        required/>
        
                    </div>
      
                    <div className="row" style={{ paddingTop: "10px" }}>    
                        <label style={{ color: "red" }}> * = Mandatory field</label>            
                    </div>
      
                    <div
                      className="row"
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "center"
                      }}
                    >
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={e => {
                          this.submitAmount(e, actionType, refund_row_num, true);
                          close();
                        }}
                      >
                        {" "}
                        Submit{" "}
                      </button>
                      &nbsp; &nbsp;
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => {
                          close();
                        }}
                      >
                        {" "}
                        Close{" "}
                      </button>
                    </div>
                  </div>
                )}
            </Popup>
         ) : (
          <Popup 
            trigger={actionType === "Edit" ? (<button className="btn-refund-api" > {actionType} </button>) : (<button className="btn btn-primary" > <b>{actionType}</b> </button>)} 
            modal 
            closeOnDocumentClick 
            contentStyle={PopupContentStyle2}>
              {close => (
                <div>
                  <div style={{backgroundColor: '#283048', textAlign:'center', height: "50px"}}>
                      {actionType === "Edit" ? (<label style={{color:'white', fontSize:'20px', paddingTop:'5px'}}> Refund {actionType} </label>) : 
                                            (<label style={{color:'white', fontSize:'15px', paddingTop:'5px'}}> {actionType} </label>)}
                  </div>
    
                  <div style={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
                    { actionType === "Refund" || actionType === "Manual Refund" ? 
                      (
                        <SingleInput
                          id="refundAmount"
                          label="Amount"
                          name="refundAmount"
                          autoComplete="off"
                          placeholder="0.00"
                          pattern="^\d{1,8}\.\d{0,2}$|^\d{1,8}$"
                          title="The number input must start with a number and use a dot as a decimal character."
                          marginTop="4px"
                          width="50%"
                          onChange={this.onValueChange}
                          errorMsg={this.errorTextAmount}
                          showErrorMsg={this.props.amountInputWrong}
                          defaultValue={this.state.refundAmount}
                        />
                      ) :
                      (
                        <SingleInput
                          id="amount"
                          label="Amount"
                          name="amount"
                          autoComplete="off"
                          placeholder="0.00"
                          pattern="^\d{1,8}\.\d{0,2}$|^\d{1,8}$"
                          title="The number input must start with a number and use a dot as a decimal character."
                          marginTop="4px"
                          width="50%"
                          onChange={this.onValueChange}
                          errorMsg={this.errorTextAmount}
                          showErrorMsg={this.props.amountInputWrong}
                        />
                      )
                      }
                  </div>

                  <div style={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
                    { actionType === "Refund" || actionType === "Manual Refund" ?
                      ( 
                        <SingleInput
                        id="externalID"
                        label="External ID"
                        name="externalID"
                        autoComplete="off"
                        pattern="^\d{1,8}\.\d{0,2}$|^\d{1,8}$"
                        title="The number input must start with a number and use a dot as a decimal character."
                        marginTop="4px"
                        width="50%"
                        onChange={this.onValueChange}
                        errorMsg={this.errorTextAmount}
                        showErrorMsg={this.props.amountInputWrong}
                        isOptional={true}
                      />
                      ) : actionType === "CFT" ?
                      (
                        <SingleInput
                        id="cftExternalID"
                        label="External ID"
                        name="cftExternalID"
                        autoComplete="off"
                        pattern="^\d{1,8}\.\d{0,2}$|^\d{1,8}$"
                        title="The number input must start with a number and use a dot as a decimal character."
                        marginTop="4px"
                        width="50%"
                        onChange={this.onValueChange}
                        errorMsg={this.errorTextAmount}
                        showErrorMsg={this.props.amountInputWrong}
                        isOptional={true}
                      />
                      ) : null
                    }
                  </div>

                  <div style={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
                    { actionType === "Refund" || actionType === "Manual Refund"  ?
                    (
                      <>
                      <TextAreaInput
                        id="refundComment"
                        label="Comment"
                        name="refundComment"
                        autoComplete="off"
                        placeholder="Please type in your refund comment.."
                        marginTop="4px"
                        isOptional={true}
                        onChange={this.onValueChange}
                        errorMsg={this.errorTextAmount}
                        showErrorMsg={this.props.amountInputWrong}
                        defaultValue={this.props.usePrevFormData ? this.props.prevFormData["amount"] : ""}
                      />
                      <div style={{textAlign:"right",color:200 - this.state.refundComment.length < 0 ? "red" :"rgb(128, 128, 128)"}}>
                        {this.state.refundComment ? 200 - this.state.refundComment.length < 0 ? "Text length exceeded 200 characters" : 200 - this.state.refundComment.length + " characters remaining" : "200 characters remaining"}
                      </div>
                    </>
                    ): actionType === "CFT" ?
                    (
                      <>
                      <TextAreaInput
                        id="cftComment"
                        label="Comment"
                        name="cftComment"
                        autoComplete="off"
                        placeholder="Please type in your cft comment.."
                        marginTop="4px"
                        isOptional={true}
                        onChange={this.onValueChange}
                        errorMsg={this.errorTextAmount}
                        showErrorMsg={this.props.amountInputWrong}
                        defaultValue={this.props.usePrevFormData ? this.props.prevFormData["amount"] : ""}
                      />
                      <div style={{textAlign:"right",color:200 - this.state.cftComment.length < 0 ? "red" :"rgb(128, 128, 128)"}}>
                        {this.state.cftComment ? 200 - this.state.cftComment.length < 0 ? "Text length exceeded 200 characters" : 200 - this.state.cftComment.length + " characters remaining" : "200 characters remaining"}
                      </div>
                    </>
                    ) : null
                    }
                  </div>
    
                <div
                  className="row"
                  style={{
                    padding: "20px",
                    textAlign: "center",
                    margin: "0px"
                  }}
                >

                  <button
                    className="secondaryBtn"
                    style={{float:"left"}}
                    type="button"
                    onClick={() => {
                      this.setState({
                        refundComment:"",
                        cftComment: ""
                      })
                      close();
                    }}
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                  &nbsp; &nbsp;
                  <button
                    className="primaryBtn"
                    style={{float:"right"}}
                    type="button"
                    disabled={200 - this.state.refundComment.length < 0 || 200 - this.state.cftComment.length < 0}
                    onClick={e => {
                      this.submitAmount(e, actionType, refund_row_num);
                      close();
                    }}
                  >
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </div>
            )}
          </Popup>
          
        ) 
    );
  };

  cancelConfirm = () => {
    this.setState({
      showConfirm: false,
      fieldConfirm: "",
      confirmTitle: "",
      amount: "",
    });
  };

  cancelAlert = () => {
    this.setState({
      inputNotValid: false,
      walletAddressNotValid: false,
      transactionFailed: false
    });
    this.setRefundAmount();
  };

  saveConfirm = (transaction_history_id_arr, currency, refund_amount_arr, paymentmethod_id, cft_amount_arr, cft_transaction_id_arr) => {
    
    let transaction_history_id = transaction_history_id_arr[this.state.refund_button_row];
    let refund_amount = refund_amount_arr[this.state.refund_button_row];
    let cft_amount = cft_amount_arr[this.state.cft_button_row];
    let cft_transaction_id = cft_transaction_id_arr[this.state.cft_button_row];
    
    switch (this.state.fieldConfirm) {
      case "Cancel":
        this.apiCall("cancel");
        break;
      case "Capture":
        this.apiCall("capture");
        break;
      case "Refund":
        this.apiCall("refund", currency, "", paymentmethod_id);
        break;
      case "Manual Refund":
        this.apiCall("manual_refund", currency, "", paymentmethod_id);
        break;
      case "CFT":
        this.apiCall("cft", currency, "", paymentmethod_id);
        break;
      case "Refund Cancel":
        this.apiCall("refund_cancel", currency, refund_amount, "", transaction_history_id)
        break;
      case "Refund Edit":
        this.apiCall("refund_edit", currency, "", "", transaction_history_id)
        break;
      case "CFT Cancel":
        if (cft_transaction_id)
        {
          this.apiCall("cft_cancel", currency, cft_amount, "", "", cft_transaction_id)
        }
        else
        {
          this.apiCall("cft_cancel", currency, cft_amount)
        }
        break;
      default:
        this.setState({
          showConfirm: !this.state.showConfirm,
          fieldConfirm: "",
          confirmTitle: "",
        });
 		}
   	
    this.setState({
      showConfirm: !this.state.showConfirm,
      refundAmount: "",
      externalID: "",
      refundComment: "",
      cftExternalID: "",
      cftComment: "",
    });
  };

  addSpaceAndUppercase(name) {
    if (name === 'ccn') {
      return 'Credit Card Number';
    }

    if (name === 'cctype') {
      return 'Credit Card Type';
    }

    if (name === 'refund_comment' || name === 'cft_comment') {
      return 'Withdrawal Comment';
    }

    let newName = name.split('_').join(' '); // replacing '_' with space
    newName = newName.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); // Making first letter of each word capital
    return newName;
  }

  renderErrorMessage(error_code, error_message, sub_errors) {
    
    return(
        <div className='container-fluid'>

          <table className="table table-bordered stripped">

            <tbody id="errorTableBody">
              <tr className='errorTable-row'>
                <td className='errorTable-col'>{error_code}</td>

                { error_code !== '0' && error_code !== '2000'? ( 
                  <td className='errorTable-col'>{error_message}</td>
                  ) : null }

              </tr>

              { sub_errors ? (
                Object.keys(sub_errors).map((item,index) => { 

                  if(sub_errors[item].error_code !== '0') {

                    return (
                      <tr key={index} className='errorTable-row'>
                        <td className='errorTable-col'>{sub_errors[item].error_code}</td> 
                        <td className='errorTable-col'>{sub_errors[item].error_message}</td>
                      </tr>
                    );
                  }

                  else {
                    return null;
                  }                                                        
              }) ) 
              
              : null
            }

            </tbody>

          </table>

        </div>
    );
  }

  renderTransactionHistoryHeadings(value, tx_history_header_arr) {

    /* ######################################################
    Determining the data with largest length from value
    ########################################################*/

    let largest_data = {};
    let largest_length = 0;

    for (let item in value) {
      if (Object.keys(value[item]).length > largest_length) {
        largest_length = Object.keys(value[item]).length;
        largest_data = value[item];
      }
    }

    /* ######################################################
    Returning keys of the largest data as Headers of Transaction History table
    ########################################################*/

    return (       
      <tr className="transdetails-row">
        {Object.keys(largest_data).map((item, index) => {  // Nested-nested loop
          if (item === 'counter') {
            tx_history_header_arr.push(item);
            return (
              <td key={index} className="transdetails-history-col-header"> No. </td>
            ); 
          }
          else if (item === 'user_permission') {
            tx_history_header_arr.push(item);
            return (
              <td key={index} className="transdetails-history-col-header"> User </td>
            ); 
          }
          else if (item === 'date_time') {
            tx_history_header_arr.push(item);
            return (
              <td key={index} className="transdetails-history-col-header"> Date & Time </td>
            ); 
          }
          else if (item === 'can_cft_cancel') {
            tx_history_header_arr.push(item);
            return (
              <td key={index} className="transdetails-history-col-header"> Cancel CFT </td>
            ); 
          }
          else if (item === 'transaction_history_id' || item === 'error_message' || item === 'reporting_date' || item === 'expiration_date' 
                    || item === 'currency' || item === 'sub_errors' || item === 'paymentMethodId' || item === 'can_refund_edit' || item === 'can_refund_cancel') {
            return null;
          }
          else {
            tx_history_header_arr.push(item);
            return (
              <td key={index} className="transdetails-history-col-header">{this.addSpaceAndUppercase(item)}</td>
            );
          }                                                                        
        })}
      </tr> 
    );    
  }

  render() {
    var transaction_history_id_arr = [];
    var currency = "";
    var refund_amount_arr = [];
    var paymentmethod_id = "";
    var refund_row_num = -1;
    var tx_history_header_arr = [];
    var cft_tx_history_headers = [];
    var isTxLocked = false;
    var lock_date = '';
    var isDemo = false;
    var cft_row_num = -1;
    var cft_amount_arr = [];
    var cft_transaction_id_arr = [];
    
    return (
      <div>
        {this.state.getTransDetails_response && Object.keys(this.state.getTransDetails_response).length !== 0 ? (
          <div>
            <div className="filter-chips">

              {Object.keys(this.state.getTransDetails_response).map((item, index) => {  // Main Loop

                // Getting currency from order_details
                if (item === 'order_details') {
                  currency = this.state.getTransDetails_response[item].currency;            
                }

                // Getting paymentmethod_id of the first row of transaction_history
                if (item === 'transaction_history') {
                  paymentmethod_id = this.state.getTransDetails_response[item][0].paymentMethodId;      
                }

                //------------------------- if the item has key,value pairs ------------------------------------------

                if (Object.keys(this.state.getTransDetails_response[item]).length !== 0) {

                  return (         
                    <div key={index} >

                      {item !== 'transaction_history' && item !== 'actions' && item !== 'related_cft_transaction_history'? ( 

                          <table className="table stripped"> 

                            <thead style={{ backgroundColor: "#283048", color: "white" }}>
                              <tr>
                                <th scope="col" colSpan="2" className="table-danger" style={{fontSize:'15px'}}>
	                                { this.addSpaceAndUppercase(item) }
	                                <span style={{ float: "right" }}>
		                                { this.state.getTransDetails_response["order_details"] && this.addSpaceAndUppercase(item) === "Order Details" ? 
		                                	"Merchant: " + this.state.getTransDetails_response["order_details"].merchant_name : "" }
	                                </span>
                                </th>
                              </tr>
                            </thead>

                            <tbody style={{color: '#235669'}}>                      
                            {Object.keys(this.state.getTransDetails_response[item]).map((child_item, key) => {  //Nested Loop

                              if (typeof(this.state.getTransDetails_response[item][child_item]) !== 'undefined' && this.state.getTransDetails_response[item][child_item] !== null 
                                && this.state.getTransDetails_response[item][child_item] !== 'N/A' && this.state.getTransDetails_response[item][child_item] !== 'none') { 

                                if(this.state.getTransDetails_response[item][child_item].toString().trim().length) { // checking if child_item contains anything else except space, tab or newline (not empty)

                                  if (typeof(this.state.getTransDetails_response[item][child_item]) !== 'object') {

                                    if (child_item === 'amount' || child_item === 'remaining_amount' || child_item === 'booked') {
                                      return (         
                                        <tr key={key} className="transdetails-row">
                                          <td className="transdetails-col-header">{this.addSpaceAndUppercase(child_item)}</td>
                                          <td className="transdetails-col-value">{this.state.getTransDetails_response[item][child_item]} {" "} {currency}</td>                              
                                        </tr> 
                                      );
                                    }

                                    else if (child_item === 'bl_blocked_by_transaction_id' || child_item === 'original_tx') {

                                      return (         
                                        <tr key={key} className="transdetails-row">
                                          <td className="transdetails-col-header">{this.addSpaceAndUppercase(child_item)}</td>
                                          
                                          <td className="transdetails-col-header">
                                            <label onClick={e => { e.preventDefault(); 
                                                                helper.renderTransDetails(this.state.getTransDetails_response[item][child_item]); 
                                                              }}

                                                  style={{ cursor: "pointer", textDecoration: "underline" }}>
                                                  {this.state.getTransDetails_response[item][child_item]}
                                            </label>
                                          </td> 

                                        </tr> 
                                      );
                                    }

                                    else if (child_item === 'currency' || child_item === 'bl_blocked' || child_item === 'locked' || child_item === 'lock_date' || 
                                              child_item === 'pmState' || child_item === 'merchant_id') {

                                      if (child_item === 'locked') {
                                        if (this.state.getTransDetails_response[item][child_item] === 1){
                                          isTxLocked = true;
                                          lock_date = this.state.getTransDetails_response[item].lock_date;
                                        }
                                      }

                                      if (child_item === 'pmState') {
                                        if (this.state.getTransDetails_response[item][child_item] === 'DEMO'){
                                          isDemo = true;
                                        }
                                      }

                                      return null;
                                    }

                                    else {
                                      return (         
                                        <tr key={key} className="transdetails-row">
                                          <td className="transdetails-col-header">{this.addSpaceAndUppercase(child_item)}</td>
                                          <td className="transdetails-col-value">{this.state.getTransDetails_response[item][child_item]}</td>
                                        </tr> 
                                      );
                                    }

                                  }

                                }

                              }

                              return null;
                              
                            })}

                            </tbody>                          

                            { isTxLocked === true ? (

                              <tbody>
                                <tr className='empty-row'></tr>
                                <tr className='tx-locked-demo'>
                                  {isTxLocked = false}
                                  <th scope="col" colSpan="20" className="table-danger" style={{fontSize:'15px', textAlign:'center'}}>
                                    Tx is locked since {lock_date}
                                  </th>
                                </tr>
                              </tbody>

                              ) : null }

                            { isDemo === true ? (

                              <tbody>
                                <tr className='empty-row'></tr>  
                                <tr className='tx-locked-demo'>
                                  {isDemo = false}
                                  <th scope="col" colSpan="20" className="table-danger" style={{fontSize:'15px', textAlign:'center'}}>
                                    Hint: this is a DEMO-transaction <br></br>
                                    (each action will be sent to a test-gateway)
                                  </th>
                                </tr>
                              </tbody>

                            ) : null }

                          </table>

                        ) : item === 'transaction_history' || item === 'related_cft_transaction_history'? (
                          <div className='table-div'>
                            <table className="table table-bordered stripped">

                              <thead style={{ backgroundColor: "#283048", color: "white"}}>
                                <tr>
                                  <th scope="col" colSpan="20" className="table-danger" style={{fontSize:'15px'}}>
                                  {this.addSpaceAndUppercase(item)}
                                  </th>
                                </tr>
                              </thead>
                          
                              <tbody style={{color: '#235669', textAlign:'center'}}>

                                { 
                                  item === 'transaction_history' ? (
                                  this.renderTransactionHistoryHeadings(this.state.getTransDetails_response[item], tx_history_header_arr)
                                  ) : this.renderTransactionHistoryHeadings(this.state.getTransDetails_response[item], cft_tx_history_headers) 
                                }

                                {Object.keys(this.state.getTransDetails_response[item]).map((child_item, key) => {   //Nested Loop

                                  let header_arr = [];

                                  if (item === 'transaction_history') {
                                    header_arr = tx_history_header_arr;
                                  }
                                  else {
                                    header_arr = cft_tx_history_headers;
                                  }
                                
                                  return (   
                                    <tr key={key} className="transdetails-row"> 
                                    
                                      { Object.keys(header_arr).map((header, keyHeader) => {
                                          
                                          let row_keys = [];
                                          
                                          //-----------------------------------All keys from a row of transaction_history pushed in row_keys ---------------------------------
                                          Object.keys(this.state.getTransDetails_response[item][child_item]).map((item2) => {
                                            row_keys.push(item2);
                                            return null;
                                          });

                                          //------------------------------------------- If each header is in row_keys ---------------------------------------------------------
                                          if (row_keys.includes(header_arr[header])) { 

                                            return(

                                              Object.keys(this.state.getTransDetails_response[item][child_item]).map((child_item2, key2) => {  // Nested-nested loop  

                                                if ( child_item2 === header_arr[header]) {

                                                  //-------------------------------- If there is a value for the respected key -------------------------------------------------

                                                  if (this.state.getTransDetails_response[item][child_item][child_item2]) {

                                                    //----------------------------------------------- REFUND Column-------------------------------------------------

                                                    if ( child_item2 === 'type' && (this.state.getTransDetails_response[item][child_item][child_item2] === 'Refund'
                                                    || this.state.getTransDetails_response[item][child_item][child_item2] === 'Manual Refund' )) {

                                                      // Getting the transaction_history_ids and amounts from the 'Refund' rows
                                                      transaction_history_id_arr.push(this.state.getTransDetails_response[item][child_item].transaction_history_id);
                                                      refund_amount_arr.push(this.state.getTransDetails_response[item][child_item].amount);

                                                      let can_refund_edit_flag = this.state.getTransDetails_response[item][child_item].can_refund_edit;
                                                      let can_refund_cancel_flag = this.state.getTransDetails_response[item][child_item].can_refund_cancel;
                  
                                                      // Needed to keep counting the row number
                                                      refund_row_num = refund_row_num + 1;    
                                                      
                                                      // Buttons are added in the Refund column
                                                      return (
                                                        <td key={key2} className="transdetails-col-value" >
                                                          
                                                          {this.state.getTransDetails_response[item][child_item][child_item2]}
                                                          <br></br>
                                                          {can_refund_edit_flag === "1" ? (this.createPopup("Edit", refund_row_num)) : 
                                                                                            (<button className="btn-refund-api-disabled" disabled> Edit </button>)}
                                                          <br></br>
                                                          {can_refund_cancel_flag === "1" ? (<button className="btn-refund-api" value = {refund_row_num} onClick={this.refundConfirmation}> Cancel </button>) : 
                                                                                            (<button className="btn-refund-api-disabled" disabled> Cancel </button>)}
                                                          
                                                        </td>
                                                      );
                                                    }

                                                    //--------------------------------------------- CFT Cancel Column ------------------------------------------------

                                                    else if ( child_item2 === 'can_cft_cancel' && this.state.getTransDetails_response[item][child_item][child_item2] === '1') {

                                                      cft_amount_arr.push(this.state.getTransDetails_response[item][child_item].amount);

                                                      if ( item === 'related_cft_transaction_history')
                                                      {
                                                        cft_transaction_id_arr.push(this.state.getTransDetails_response[item][child_item].transaction_id);
                                                      }

                                                      // Need to keep counting the row number
                                                      cft_row_num = cft_row_num + 1;

                                                      return (
                                                        <td key={key2} className="transdetails-col-value" >
                                                      
                                                          <button className="btn-refund-api" value = {cft_row_num} onClick={this.cftConfirmation}> Cancel </button>
                                                          
                                                        </td>
                                                      );
                                                    }

                                                    //----------------------------------------------- Status Column-------------------------------------------------

                                                    else if ( child_item2 === 'error_code') {

                                                      let error_message = this.state.getTransDetails_response[item][child_item].error_message;
                                                      let sub_errors = this.state.getTransDetails_response[item][child_item].sub_errors;
                                                      let error_code = this.state.getTransDetails_response[item][child_item].error_code;
                                                      
                                                      if (error_code !== '0' ){
                                                        return (
                                                          <td key={key2} className="transdetails-col-value">                                                    
    
                                                            <section>
                                                              {this.state.getTransDetails_response[item][child_item][child_item2]}
                                                              <button className="message-button">
                                                                <span style={{fontSize:'16px'}}>
                                                                  <i className="fa fa-info-circle" style={{color: 'red'}}/>
                                                                </span> 
                                                                <span className="message-button-hover">{this.renderErrorMessage(error_code, error_message, sub_errors)}</span>
                                                              </button>
                                                            </section> 
                                                                                                              
                                                          </td>
                                                        );
                                                      }

                                                      else {
                                                        return (
                                                          <td key={key2} className="transdetails-col-value" >

                                                            <p style={{paddingTop: '5px', float:'left'}}> {" "} </p>
                                                          
                                                          </td>
                                                        );
                                                      }
                                                    }

                                                    //----------------------------------------------- AMOUNT Column-------------------------------------------------

                                                    else if ( child_item2 === 'amount') {

                                                      let this_currency = this.state.getTransDetails_response[item][child_item].currency;
                                                      
                                                      return (
                                                        <td key={key2} className="transdetails-col-value">
                                                          <p style={{paddingTop: '5px'}}>{this.state.getTransDetails_response[item][child_item][child_item2]} &nbsp;&nbsp;
                                                          {this_currency} </p>
                                                        </td>
                                                      );
                                                      
                                                    }

                                                    //----------------------------------------------- DATE_TIME Column-------------------------------------------------

                                                    else if ( child_item2 === 'date_time') {
                                                      let reporting_date = this.state.getTransDetails_response[item][child_item].reporting_date;
                                                      let expiration_date = this.state.getTransDetails_response[item][child_item].expiration_date;

                                                      if (reporting_date && expiration_date){
                                                        return (
                                                          <td key={key2} className="transdetails-col-value" >
                                                            <p style={{paddingTop: '5px'}}> 
                                                              {this.state.getTransDetails_response[item][child_item][child_item2]} <br></br>
                                                              Reporting Time <br></br>
                                                              {reporting_date}<br></br>
                                                              Expiration Time <br></br>
                                                              {expiration_date} 
                                                            </p>
                                                          </td>
                                                        );
                                                      }

                                                      else if (reporting_date){
                                                        return (
                                                          <td key={key2} className="transdetails-col-value" >
                                                            <p style={{paddingTop: '5px'}}>
                                                              {this.state.getTransDetails_response[item][child_item][child_item2]} <br></br>
                                                              Reporting Time <br></br>
                                                              {reporting_date}<br></br>
                                                            </p>
                                                          </td>
                                                        );
                                                      }

                                                      else if (expiration_date){
                                                        return (
                                                          <td key={key2} className="transdetails-col-value" >
                                                            <p style={{paddingTop: '5px'}}>
                                                              {this.state.getTransDetails_response[item][child_item][child_item2]} <br></br>
                                                              Expiration Time <br></br>
                                                              {expiration_date}<br></br>
                                                            </p>
                                                          </td>
                                                        );
                                                      }

                                                      else {
                                                        return (
                                                          <td key={key2} className="transdetails-col-value" >
                                                            <p style={{paddingTop: '5px'}}>
                                                              {this.state.getTransDetails_response[item][child_item][child_item2]}
                                                            </p>
                                                          </td>
                                                        );
                                                      }
                                                    }

                                                    //----------------------------------------------- GENERAL Columns -------------------------------------------------
                                                    
                                                    else {
                                                      return (
                                                        <td key={key2} className="transdetails-col-value" style={{textAlign: child_item2 === 'refund_comment' ? "left" : "center"}}>
                                                          { child_item2 === 'refund_comment' ?
                                                            <p style={{paddingTop: '5px', width:"270px", wordWrap:"break-word"}}>
                                                              {this.state.getTransDetails_response[item][child_item][child_item2]}
                                                            </p> :
                                                            <p style={{paddingTop: '5px'}}>
                                                              {this.state.getTransDetails_response[item][child_item][child_item2]}
                                                            </p>
                                                          }
                                                        </td>
                                                      );
                                                    }
                                                  }

                                                  //-------------------------------- If there is no value for the respected key -------------------------------------------------

                                                  else {
                                                    return (
                                                      <td key={key2} className="transdetails-col-value" >{" "}</td>
                                                    );
                                                  }
                                                }
                                                else {
                                                  return null;
                                                } 

                                              })
                                              
                                            );
                                          }

                                          // If header is not row_keys
                                          else {
                                            return (
                                              <td key={keyHeader} className="transdetails-col-value" >{" "}</td>
                                            );
                                          }
                                      })
                                      }
                                
                                    </tr> 
                                  );
                                })}  

                              </tbody>

                            </table>
                          </div>

                        ) : item === 'actions' ? (
                          <div>
                            <table className="table table-bordered stripped">
                              <thead className="thead-dark" style={{ backgroundColor: "#283048", color: "white" }}>
                                <tr>
                                  <th scope="col" colSpan="5" className="table-danger" style={{fontSize:'15px'}}>
                                    {this.addSpaceAndUppercase(item)}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td className="align-center">
                                    {this.state.can_capture === true ? (
                                      <button className="btn btn-primary" onClick={this.testConfirmation}> <b>Capture</b> </button>
                                    ) : (
                                      <button className="btn btn-secondary" disabled> <b>Capture</b> </button>
                                    )}
                                  </td>
                                  <td className="align-center">
                                    {this.state.can_cancel === true ? (
                                      <button className="btn btn-primary" onClick={this.testConfirmation}> <b>Cancel</b> </button>
                                    ) : (
                                      <button className="btn btn-secondary" disabled> <b>Cancel</b> </button>
                                    )}
                                  </td>
                                  <td className="align-center">
                                    {this.state.can_refund === true ? (
                                      <div>{this.createPopup("Refund")}</div>
                                    ) : (
                                      <button className="btn btn-secondary" disabled> <b>Refund</b> </button>
                                    )}
                                  </td>
                                  <td className="align-center">
                                    {this.state.can_manual_refund === true ? (
                                      <div>{this.createPopup("Manual Refund")}</div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </td>
                                  <td className="align-center">
                                    {this.state.can_cft === true ? (
                                      <div>{this.createPopup("CFT")}</div>
                                    ) : (
                                      <button className="btn btn-secondary" disabled> <b>CFT</b> </button>
                                    )}
                                  </td>
                                </tr>
                              </tbody>

                            </table>
                          </div>
                          
                        ) : null
                      }
                      
                    </div> 
                  );
                }

              //------------------------- if the item has no key,value pairs ------------------------------------------
              else {
                return null;
              }
                
              })}

            </div>
          </div>
        ) 
        :
        ( <div className='centered-element'>
            {" "}
            <ScaleLoader color={"rgb(46, 128, 178)"} height={70} width={15} />
            {" "}
          </div> )}

        {this.state.transactionFailed ? (
          <ALERT
            show={this.state.transactionFailed}
            handleClose={this.cancelAlert}
            text={this.state.errormessage}
            title={this.state.fieldConfirm}
          />
        ) : null}

        {this.state.showConfirm && (
         <CONFIRM
            show={this.state.showConfirm}
            handleSave={() => this.saveConfirm(transaction_history_id_arr, currency, refund_amount_arr, paymentmethod_id, cft_amount_arr, cft_transaction_id_arr)}
            handleClose={this.cancelConfirm}
            title={this.state.fieldConfirm}
            confirmText={this.state.confirmTitle}
         /> 
        )}
        {this.state.inputNotValid ? (
          <ALERT
            show={this.state.inputNotValid}
            handleClose={this.cancelAlert}
            text={"Given amount is not valid!"}
          />
        ) : null}
       
   			{this.state.walletAddressNotValid ? (
        	<ALERT
            show={this.state.walletAddressNotValid}
            handleClose={this.cancelAlert}
            text={"Given wallet address is not valid!"}
          />
					) : null}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  transDetailsData: state.transLogReducer,
  sideBarData: state.dashboardReducer
});

const mapDispatchToProps = {
  getTransDetails,
  interfacePaymentCall,
  getPermissions
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionDetails)
);
