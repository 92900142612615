import React, { Component } from "react";
import { connect } from "react-redux"; 
import { Button } from 'react-bootstrap';
import {   boardPayPalMerchantAction, boardPayPalMerchantStatusAction, boardPayPalMerchantDisableAction } from "../../../actions/boardMerchantAction";
import { withRouter } from "react-router-dom"; 
import { ScaleLoader } from "react-spinners";
import queryString from 'query-string';
 
import { Redirect } from "react-router-dom";
 
 
  
 

class MerchantBoard extends Component {
  constructor(props) {

	super(props);
	
    this.state = {    	
			error: false,
			notification: false,    	
			isLoading: false,    
			clickedPayPalMerchantBoardBtn: false,
			clickedPayPalMerchantDisableBtn: false,
			redirectUrlPresent: false,	 
			showBoardButton: false,
			showDisableButton: false        
		}; 
   	
		this.errorMsg = "";   
		this.notificationMsg = "";    
		this.boardingRedirectUrl = "";
		this.interval = "";
		this.timer = 0;
		this.redirect = false;
		this.boardedMerchantPayPalAccountId = "";
		this.accountId = "";
		this.businessEmail = "";
		this.country = "";
		this.primary_currency = "";		 
		this.selectedMerchanId = "";



		this.get_board_status = false;       
	
		this.resolvePayPalMerchantBoardBtnState = this.resolvePayPalMerchantBoardBtnState.bind(this);
		this.handlePayPalMerchantBoardBtnClick = this.handlePayPalMerchantBoardBtnClick.bind(this);
		this.resolvePayPalMerchantDisableBtnState = this.resolvePayPalMerchantDisableBtnState.bind(this);
		this.handlePayPalMerchantDisableBtnClick = this.handlePayPalMerchantDisableBtnClick.bind(this);
		this.handleSelectMerchant = this.handleSelectMerchant.bind(this);
		this.boardPayPalMerchant = this.boardPayPalMerchant.bind(this);
		this.boardPayPalMerchantStatusCheck = this.boardPayPalMerchantStatusCheck.bind(this);
  	}
  	//constructor finshed

	//Notification functions
	addNotification(type, text) {
		// create the DIV and add the required classes
		const newNotification = document.createElement('div');
		
		newNotification.classList.add('notification', `notification-${type}`);

		const innerNotification = `
			<strong>${type}:</strong> ${text}
		`;

		// insert the inner elements
		newNotification.innerHTML = innerNotification;

		// add the newNotification to the container
		notificationContainer.appendChild(newNotification);

		return newNotification;
	}

	removeNotification(notification) {

		notification.classList.add('hide');

		// remove notification from the DOM after 0.5 seconds
		setTimeout(() => {
			notificationContainer.removeChild(notification);
		}, 500);
	}

	demoNotification()
	{
		// adding and removing notification for DEMO purposes
		const info = this.addNotification(NOTIFICATION_TYPES.INFO, 'Info text added');
		setTimeout(() => {
						this.removeNotification(info);
			}, 5000);
	}
	  
	//Handling Board Merchant button click
	async handlePayPalMerchantBoardBtnClick(event){

		event.preventDefault();

		this.setState({
			notification: false,
			isLoading: true,
			clickedPayPalMerchantBoardBtn: true,
		});
					
		await this.boardPayPalMerchant()
			
		this.resolvePayPalMerchantBoardBtnState();
	}
	
	//Handling Disable Merchant button click
	async handlePayPalMerchantDisableBtnClick(event){

		event.preventDefault();

		this.setState({
			notification: false,
			isLoading: true,
			clickedPayPalMerchantDisableBtn: true,
		});
					
		await this.disablePayPalMerchant()
			
		this.resolvePayPalMerchantDisableBtnState();
	}

	//calling board api 
	async boardPayPalMerchant(){	 

		let url_return = "https://portal.powercash21.com/generic/merchantBoardTab";

		url_return = `${window.location.origin.toString()}/generic/merchantBoardTab`

		//for testing redirect back
		//url_return = "https://webhook.site/36418ac5-815e-4171-a4dd-1468058b68d4";
		//for testing redirect back

		//localStorage.setItem("merchant_id", this.state.merchant_id);		 

		await this.props.boardPayPalMerchantAction({merchantId: this.state.merchant_id, url_return: url_return });

		//Default
		this.errorMsg = "Something went wrong, please contact our tech support team to assist.";
		this.setState({
						error:true,
						redirectUrlPresent:false,
						notification:false,
						merchant_id:this.state.merchant_id
						
					});

		if( !this.props.merchantBoardReducer || !this.props.merchantBoardReducer.boardResponse)
		{
			 return;
		}

		let boardResponse = this.props.merchantBoardReducer.boardResponse;

		if(boardResponse.status === "success" && boardResponse.boardingRedirectUrl)
		{
			 
			this.boardingRedirectUrl = this.props.merchantBoardReducer.boardResponse.boardingRedirectUrl;			 

			this.errorMsg = "";
			this.notificationMsg = ""
			this.setState({
					error: false,
					redirectUrlPresent:true,
					merchant_id:this.state.merchant_id
				});			 
		}		
	}

	//calling disable api 
	async disablePayPalMerchant(){		

		let merchant_id_to_disable = this.state.merchant_id;

		if( !merchant_id_to_disable )
		{
			//Case when redirect back from PayPal page and clicked disable btn 
			//Pre-selected merchant account, there is no merchant_id in state
			merchant_id_to_disable = localStorage.getItem("merchant_id")
		}

		await this.props.boardPayPalMerchantDisableAction({merchantId: merchant_id_to_disable});

		//Default
		this.errorMsg = "Something went wrong, please contact our tech support team to assist.";
		this.setState({
						error:true,
						redirectUrlPresent:false,
						notification:false,
						merchant_id:this.state.merchant_id
						
					});

		if( !this.props.merchantBoardReducer || !this.props.merchantBoardReducer.boardDisableResponse)
		{
			 return;
		}

		this.errorMsg = "";
		this.businessEmail = "";
		this.accountId = "";
		this.notificationMsg = "";
		this.country = "";
		this.primary_currency = "";		

		let disableResponse = this.props.merchantBoardReducer.boardDisableResponse;

		if( disableResponse.status === "error")
		{
			this.errorMsg = disableResponse.error_message;
			
			this.setState({
					error:true,					 
					notification:false
				});
		}
		else if (disableResponse.status === "info")		 
		{
			this.notificationMsg = disableResponse.error_message;
			this.setState({
					error:true,					 
					notification:false					 
				});				 
		}
		else if (disableResponse.status === "success")
		{			 
			this.notificationMsg = disableResponse.error_message
			this.setState({
					error: false,				 
					notification: true
				});		
		}

		if (disableResponse.state === "disabled")
		{
			this.setState({
					showBoardButton: false,
				 	showDisableButton: false
			});
		}		
	}


	async boardPayPalMerchantStatusCheck(selectedMerchanId)
	{
		let merchantid = localStorage.getItem("merchant_id");		 

		//Checking boarding status on combobox selected event
		if ( selectedMerchanId !== undefined)
		{
			merchantid = selectedMerchanId;			
		}

		this.selectedMerchanId = merchantid;

		let boardedMerchantPayPalAccountId = localStorage.getItem("merchantIdInPayPal") || "";

		if(boardedMerchantPayPalAccountId)
		{
			localStorage.removeItem("merchantIdInPayPal");
		}		 

		await this.props.boardPayPalMerchantStatusAction({merchantId: merchantid, 
												boardedMerchantPayPalAccountId: boardedMerchantPayPalAccountId });

		if( !this.props.merchantBoardReducer || !this.props.merchantBoardReducer.boardStatusResponse)
		{
			this.errorMsg = "Issue checking account status";
			this.notificationMsg = "";
			this.setState({
					error:true,
					redirectUrlPresent:false,
					notification:false
				});

			return;
		}	
		
		this.errorMsg = "";
		this.businessEmail = "";
		this.accountId = "";
		this.notificationMsg = "";
		this.country = "";
		this.primary_currency = "";		


		let statusResponse = this.props.merchantBoardReducer.boardStatusResponse;		

		if( statusResponse.status === "error")
		{
			this.errorMsg = statusResponse.error_message;			 
			this.setState({
					error:true,
					redirectUrlPresent:false,
					notification:false
				});
		}
		else if (statusResponse.status === "info")		 
		{			 
			this.notificationMsg = statusResponse.error_message;
			this.setState({
					error:true,
					redirectUrlPresent:false,
					notification:false					 
				});			 
				
			if (statusResponse.state === "disabled")
			{
				this.setState({
					showBoardButton: false,
					 showDisableButton: false
				});			 
			}
			else if (statusResponse.state === "enabled")
			{
				this.setState({
					showBoardButton: true,
					 showDisableButton: true
				});				 
			}
		}
		else if (statusResponse.status === "success")
		{
			this.businessEmail = statusResponse.primary_email;
			this.accountId = statusResponse.merchant_id;
			this.country = statusResponse.country;
			this.primary_currency = statusResponse.primary_currency;		 

			if (statusResponse.error_message)
			{
				this.notificationMsg = statusResponse.error_message;
			}
			else
			{
				this.notificationMsg = "Primary email has been confirmed and payments receivable now ";
			}		
			
			this.setState({
					error: false,
					redirectUrlPresent:false,
					notification: true
				});
			 
			this.setState({
				showBoardButton: false,
				 showDisableButton: true
			});		
		}
	}	 

	getPayPalRedirectBackParams()
	{
		let params = queryString.parse(this.props.location.search);	
		this.redirect = true;
		
		if( params.merchantIdInPayPal )
		{
			localStorage.setItem("merchantIdInPayPal", params.merchantIdInPayPal);			
		}		
	}	 
	
	resolvePayPalMerchantBoardBtnState() {
		this.setState({
			isLoading:false,
			clickedPayPalMerchantBoardBtn:false,
		});
	} 
	
	resolvePayPalMerchantDisableBtnState(){
		this.setState({
			isLoading:false,
			clickedPayPalMerchantDisableBtn:false,
		});
	}
	
	handleSelectMerchant(event){
		event.preventDefault();		

		const {name, value} = event.target;

		this.setState({
			[name]: value
		});

		let selectedMerchanName = value;

		localStorage.setItem("merchant_id",selectedMerchanName);

		this.boardPayPalMerchantStatusCheck(selectedMerchanName);
	}

	openPopupUrl(url) {
		const win = window.open(url, '_blank');
		if (win != null) {
		  win.focus();
		}
		this.setState({
			redirectUrlPresent: false
		});
	}

	createPayPalFlow()
	{

		var js, ref = document.getElementsByTagName("script")[0];	

      if (!document.getElementById("paypal-js")) {	

        js = document.createElement("script");
        js.id = "paypal-js";
        js.async = true;
        js.src = "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
        ref.parentNode.insertBefore(js, ref);
      }
	} 

	componentDidMount() 
	{

 	} 
	 
   /**
   * Checks if an object has no properties.
   * @param {object} obj 
   * @returns {boolean}
   */
  isObjectEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
 
  render() { 	 

	if (this.redirect){
		this.redirect = false;	
		this.get_board_status = true; 
		return <Redirect to={{ pathname: "/generic/merchantBoardTab" }} />;	
	}



	if (this.props.location.search  )
	{		 
		this.getPayPalRedirectBackParams();
		this.boardPayPalMerchantStatusCheck();
	}

	if (this.state.redirectUrlPresent)
	{
		this.embedded_url= this.boardingRedirectUrl +  "&displayMode=minibrowser";	
	
		this.createPayPalFlow();
	}

	//For boarding, cover with permission
	let serverFilterData = this.props.serverFilterData;

	let merchantOptions = [];
    if (!this.isObjectEmpty(serverFilterData) && serverFilterData["merchant_name"]) {
    	serverFilterData["merchant_name"].map(v => merchantOptions.push({label: v, value: v}));
	}
	//For boarding, cover with permission  	 
  
		return (

			<div className="admin-page">
                <h4 className="admin-heading">
                    Merchant Boarding
                </h4>		
				
				<div className="col-md-2 col-sm-2 col-xs-1 admin-col" style={{ opacity: "1", marginTop:"20px", marginBottom:"20px" }}>
					<div style={{width:"500px"}}>
						<label>Avaliable accounts for boarding with PayPal</label>
						<span className="display-label">{}</span>
					</div>
					<div className="select-custom-parent admin-select-outer-box">

						<select
                            id="merchantIDSelection"
                            className="admin-select-field"
                            style={{color: this.state["merchant_id"] ? "black" : "grey"}}
                            onChange={this.handleSelectMerchant}
                            name="merchant_id"
							// defaultValue="DEFAULT"

							 
							value={this.selectedMerchanId !== "" ? this.selectedMerchanId : "DEFAULT"}
                            disabled={this.state.loading}>
                                <option value="DEFAULT" disabled>Select account to board</option>
                                { !this.isObjectEmpty(serverFilterData) ? (
                                    serverFilterData["merchant_name"].map(obj => (
                                    <option key={obj} value={obj} style={{color:"black"}}>
                                        {obj}
                                    </option>
                                    ))
                                    ) : []
                                }
						</select>
					</div>

						<div style={{width:"500px"  }}>
							 
								{ this.state.showBoardButton && (

										
										<Button 
											onClick={this.handlePayPalMerchantBoardBtnClick}
											id="boardBtn"
											name="boardBtn"
											style={{ marginLeft:"0px",marginTop:"20px",marginRight:"10px" }}
											className="board-merchant-btn"
											disabled={this.state.isLoading}>
											{ this.state.clickedPayPalMerchantBoardBtn ?
												<ScaleLoader color={"white"} height={20} /> :
												"Board account"
											}
										</Button>
										
								)}
							 
								{ this.state.showDisableButton && (
										
											<Button 
												onClick={this.handlePayPalMerchantDisableBtnClick}
												id="disableBtn"
												name="disableBtn"
												style={{ marginLeft:"0px",marginTop:"20px" }}
												className="board-merchant-btn"
												disabled={this.state.isLoading}>
												{ this.state.clickedPayPalMerchantDisableBtn ?
													<ScaleLoader color={"white"} height={20} /> :
													"Disable account"
												}
											</Button>
											
									)}
							 					
						</div>
				</div>		 

					<div className="notification-container" id="notification-container">

						{ this.errorMsg ? ( 
											<div>
												<div className="notification notification-danger">
													<strong>Error:</strong> {this.errorMsg}
												</div>
											</div>
										) : null }
						
						{ this.notificationMsg && this.businessEmail && this.accountId ? ( 
										<div>
											<div className="notification notification-info">
												 
													<strong>Info:</strong> {this.notificationMsg}
													<ul>
														<li data-matomo-mask><strong>Email:</strong> {this.businessEmail}</li>
														<li><strong>Account Id:</strong> {this.accountId}</li>
														<li><strong>Country:</strong> {this.country}</li>
														{/* <li><strong>Currency:</strong> {this.primary_currency}</li> */}
													</ul>											 	
											</div>										 
										</div>
						) : null }

						{ this.notificationMsg && !this.businessEmail && !this.accountId ? ( 
										<div>
											<div className="notification notification-info">
												<strong>Info:</strong> {this.notificationMsg}
											</div>
										</div>
						) : null }

						{ this.state.redirectUrlPresent ? (														
																					
							<div className="notification notification-info">
								<div  dir="ltr" trbidi="on" style={{ textAlign: "left" }} >												 
								<strong>Info:</strong> You can <strong> <a data-paypal-button="true" href={this.embedded_url} target="PPFrame">
										Sign up in PayPal</a></strong> now to continue boarding.
								</div>
							</div>
														
						) : null }				



						{/* <div className="notification notification-success">
							<strong>Success:</strong> Lorem ipsum dolor sit amet consectetur adipisicing elit.
						</div>

						<div className="notification notification-warning">
							<strong>Warning:</strong> Lorem ipsum dolor sit amet, consectetur adipisicing.
						</div> */}						 
					</div>
				<div>								 			
				</div>  
     	    </div>			
		);
    }
}

const mapStateToProps = state => ({
	merchantBoardReducer: state.merchantBoardReducer,
});

const mapDispatchToProps = {	 
	boardPayPalMerchantAction,
	boardPayPalMerchantStatusAction,
	boardPayPalMerchantDisableAction
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MerchantBoard)
);

const notificationContainer = document.getElementById('notification-container');

const NOTIFICATION_TYPES = {
	INFO: 'info',
	SUCCESS: 'success',
	WARNING: 'warning',
	DANGER: 'danger'
}
