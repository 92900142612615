import React, { useState } from "react";
import Popup from "reactjs-popup";
import styles from "./ChargebackRefund.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { chargebackDecisionAction } from "../../../../actions/chargebackAlertsAction";

const ChargebackReject = ({ open, onClose, rowData, onRejectSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const processReject = async () => {
        setIsLoading(true); // Disable button
        const payload = {
            dispute_provider_alert_id: rowData.dispute_provider_alert_id,
            merchant_id: rowData.merchant_id,
            merchant_decision: "rejected",
        };

        try {
            await chargebackDecisionAction(payload);
            onRejectSuccess(true);
            onClose();
        } catch (error) {
            console.error(`Error on refund: ${error}`);
        } finally {
            setIsLoading(false); // Re-enable button after API call completes
        }
    };

    const handleRefund = () => {
      if (!isLoading) {
        processReject();
      }
    };

    const btnColor = isLoading ? "#f8879a" : "#f43f5e";
    return (
        <Popup open={open} onClose={onClose} modal>
            <div className={`${styles.popup}`}>
                <div className={styles.content}>
                    <div
                        className={styles.header}
                        style={{
                            backgroundColor: "#021d3a",
                            padding: "10px",
                            color: "white",
                            textAlign: "center",
                        }}
                    >
                        <h5 className={styles.popupContent}>Reject</h5>
                    </div>
                    <hr className={styles.separator} />
                    <div>
                        <label htmlFor="amount" className={styles.rejectLabel}>
                            Reject Collaboration
                        </label>
                        <br />
                        <label htmlFor="amount" className={styles.rejectLabel}>
                            The transaction will not be refunded. This dispute
                            will be impacted
                        </label>
                    </div>
                    <div className={styles.warningSection}>
                        <div className={styles.icon}>
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                style={{ color: "red", marginRight: "15px" }}
                            />
                        </div>
                        <div className={styles.messageTxt}>
                            If the case advances to a chargeback, your rate will
                            be impacted
                        </div>
                    </div>
                    <div className={styles.actionButtons}>
                        <button
                            onClick={onClose}
                            className={styles.cancelButton}
                        >
                            Cancel
                        </button>

                        <button
                            onClick={handleRefund}
                            style={{ backgroundColor: btnColor }}
                            className={styles.rejectButton}
                            disabled={isLoading}
                        >
                            {isLoading ? "Processing..." : "Reject"}
                        </button>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default ChargebackReject;
