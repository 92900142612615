import React, { useState } from "react";
import Popup from "reactjs-popup";
import styles from "./ChargebackRefund.module.css";

import { chargebackDecisionAction } from "../../../../actions/chargebackAlertsAction";

const ChargebackRefund = ({ open, onClose, onRefundSuccess, rowData }) => {
    const [isLoading, setIsLoading] = useState(false);

    const processRefund = async () => {
        setIsLoading(true); // Disable button
        const payload = {
            dispute_provider_alert_id: rowData.dispute_provider_alert_id,
            merchant_id: rowData.merchant_id,
            merchant_decision: "accepted",
        };

        try {
            await chargebackDecisionAction(payload);
            onRefundSuccess(true);
            onClose();
        } catch (error) {
            console.error(`Error on refund: ${error}`);
        } finally {
            setIsLoading(false); // Re-enable button after API call
        }
    };

    const handleRefund = () => {
      if (!isLoading) {
        processRefund();
      }
    };

    const btnColor = isLoading ? "#4fa4ff" : "#007bff";
    return (
        <Popup open={open} onClose={onClose} modal>
            <div className={`${styles.popup}`}>
                <div className={styles.content}>
                    <div
                        className={styles.header}
                        style={{
                            backgroundColor: "#021d3a",
                            padding: "10px",
                            color: "white",
                            textAlign: "center",
                        }}
                    >
                        <h5 className={styles.popupContent}>Refund</h5>
                    </div>
                    <hr className={styles.separator} />
                    <div>
                        <label
                            htmlFor="amount"
                            className={styles.refundHeaderText}
                        >
                            Refund transaction
                        </label>
                    </div>
                    <div>
                        <label className={styles.refundBodyText}>
                            After you click “Refund” this transaction will be
                            refunded and will not become a chargeback.
                        </label>
                    </div>
                    <div className={styles.actionButtons}>
                        <button
                            onClick={onClose}
                            className={styles.cancelButton}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleRefund}
                            style={{ backgroundColor: btnColor }}
                            className={styles.refundButton}
                            disabled={isLoading} // Disable while loading
                        >
                            {isLoading ? "Processing..." : "Refund"}
                        </button>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default ChargebackRefund;
