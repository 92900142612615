import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import moment from "moment";
import { getTransData } from "../../../actions/transLogAction";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import TransactionLogMobile from "./TransactionLogMobile";
import SubDetails from "./SubDetails";
import Download from "./Download";
import BatchRefund from "./BatchRefund";
import ALERT from "../../general/core/Alert";
import NumberFormat from "react-number-format";
import { interfacePaymentCall } from "../../../actions/transLogAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import TransactionIDCell from "./txlogTableCells/TransactionIDCell";
import BINCountryCell from "./txlogTableCells/BINCountryCell";
import CCNCell from "./txlogTableCells/CCNCell";
import StatusCell from "./txlogTableCells/StatusCell";
import DateCell from "./txlogTableCells/DateCell";
import ErrorCodeCell from "./txlogTableCells/ErrorCodeCell";
import classnames from "classnames";
import { SELECTABLE_TD, COLUMNS_ADMIN, COLUMNS_REFUND_REMAINING, COLUMNS_DEFAULT } from "../../../lib/constants/transactionLog";

let transLogDataLocal = null;
let totalRecordsCountTest = 0;
let paginationTest = [];
let generalInterval = [];
//var columns = [];
let userPermissionsLocal = [];
const itemsCountPerPage = 100;

const TransactionLogNew = ({user_permissions, showLoadingScreen, getTransData, transLogReducerData}) => {
	const [activePage, setActivePage] = useState(1);
	const [transLogDataObject, setTransLogDataObject] = useState(null);
	const [isMobile] = useState(false);
	const [showNoDataAlert, setShowNoDataAlert] = useState(false);
	const [txDownloadNotAllowed, setTxDownloadNotAllowed] = useState(false);
	const [refundNotValid, setRefundNotValid] = useState(false);
  const [selection, setSelection] = useState({});
	//const [selectAll, setSelectAll] = useState(false);
	const [loading, setLoading] = useState(false);

  const handlePageChange = async pageNumber => {
    let requestBody = {};

    let filterBody =
      transLogReducerData.filterData !== null
        ? transLogReducerData.filterData
        : {
          orderId: "",
          datetimeFrom: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          datetimeTo: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          transactionId: 0
        };

    if (filterBody?.txLogParams?.txcount) {
      delete filterBody.txLogParams.txcount;
    }

    if (generalInterval?.length) {
      filterBody.txLogParams.pageIntervalStart = [ generalInterval[0] ];
      filterBody.txLogParams.pageIntervalEnd = [ generalInterval[1] ];
    }

    else if (transLogReducerData.transLogData.pagination) {
      filterBody.txLogParams.pageIntervalStart = [ paginationTest[pageNumber][0] ];
      filterBody.txLogParams.pageIntervalEnd = [ paginationTest[pageNumber][1] ];
    }

    filterBody.txLogParams.pageId = [pageNumber];
    await getTransData(filterBody, requestBody);
    setActivePage(pageNumber);
  };

  const cancelAlert = () => {
    setShowNoDataAlert(false);
    setTxDownloadNotAllowed(false);
    setRefundNotValid(false);
  };

  const manageLoadingState = () => {
    // Compares two transaction log data objects to decide if new data has been fetched.
    // Flags will be setted in Generic to disable loading screen.
    if (transLogDataLocal) {
      if (transLogDataLocal && transLogDataLocal !== transLogDataObject) {
        setSelection({});
        setTransLogDataObject(transLogDataLocal);
        setLoading(false);
        showLoadingScreen(false);
      }
    }
  }

  const transactionsInLog = () => {
    if (transLogReducerData?.transLogData && Object.keys(transLogReducerData.transLogData).length !== 0) return true;
    else return false;
 	}

  const renderTotalCount = () => {
    if (transLogReducerData?.transLogData?.totalRecordsCount && !isNaN(transLogReducerData.transLogData.totalRecordsCount)) {
      totalRecordsCountTest = transLogReducerData.transLogData.totalRecordsCount;
      paginationTest = transLogReducerData.transLogData.pagination;
      generalInterval = transLogReducerData.transLogData.general_interval;
      return <b>{totalRecordsCountTest}</b>;
    } else {
      return <b>{totalRecordsCountTest}</b>;
    }
  }

 	const getTotalCount = () => {
		if (transLogReducerData?.transLogData?.totalRecordsCount && !isNaN(transLogReducerData.transLogData.totalRecordsCount)) {
      totalRecordsCountTest = transLogReducerData.transLogData.totalRecordsCount;
      paginationTest = transLogReducerData.transLogData.pagination;
      generalInterval = transLogReducerData.transLogData.general_interval;
      return totalRecordsCountTest;
    } else {
      return totalRecordsCountTest;
    }
 	}

  const renderCell = (row, key) => {
    if (key === "amount" || key === "counter") return (<div style={{margin:"10px 0px"}}><NumberFormat value={row.value} thousandSeparator={true} decimalScale={2} displayType={"text"} fixedDecimalScale={true} /></div>);
    else if (key === "transaction_id") return <TransactionIDCell value={row.value} original={row.original} />
    else if (key === "error_code") return <ErrorCodeCell original={row.original} />
    else if (key === "date") return <DateCell original={row.original} />
    else if (key === "transaction_status") return <StatusCell value={row.value} original={row.original} />
    else if (key === "bin_country") return <BINCountryCell value={row.value} />;
    else if (key === "ccn_masked" && row.value && row.value !== "N/A") return <CCNCell value={row.value} />
    else if (key && row.value && row.value === "Shoppayment-Init") return <p style={{margin:"10px 0px"}}>Init</p>
    else if (key && row.value) return <div className='vertical-center'><p className='margin-0'>{ row.value }</p></div>
    else return null;
  }

  const renderColumn = (label, key, minWidth, data) => {
    return {
      Header: label,
      accessor: key,
      Cell: row => renderCell(row, key),
      minWidth: minWidth,
      headerStyle: { color: "rgb(17 47 58)", textAlign: "center", fontWeight: "700", backgroundColor: "white" },
      style: { whiteSpace: "unset", textAlign: "center", color: "rgb(17 47 58)", fontWeight: "500", position: 'relative' },
      /*sortMethod: (a, b) => {
        //return new Date(b.values.lastLogin) - new Date(a.values.lastLogin);
        //let timestamp = Date.parse(new Date(row.value + ":" + row.original.time))/1000
        //console.log(timestamp);
        if (key === "date") {
          return Date.parse(new Date(b + ":" + a))/1000 - Date.parse(new Date(a + ":" + a.values.time))/1000;
        }
        return b - a;
      }*/
    };
  };

  const renderCheckbox = () => {
    return {
      Header: " ",
      width: 35,
      Cell: row => {
        return (
          <div className={classnames({"checkbox-default": true}, {"checkbox-checked": row.original.transaction_id in selection})}>
            <FontAwesomeIcon icon={faCheck} style={{width:"85%",height:"85%",margin:"2px"}} />
          </div>
        )
      },
    };
  }

  const renderArrowExpander = () => {
    return {
      expander: true,
      Header: "",
      width: 65,
      Expander: ({ isExpanded, ...rest }) =>
        <i className={classnames({
          "glyphicon": true,
          "glyphicon-menu-right": true,
          "txlog-details": !isExpanded,
          "txlog-details-expand": isExpanded
        })}/>,
      style: { cursor: "pointer", fontSize: 30, padding: "0", textAlign: "center", userSelect: "none", color: "#1E6AF6", marginTop:"2px" }
    };
  }

  const populateTXLogColumns = (data) => {
    var tmpObj = userPermissionsLocal["is_admin"] ? COLUMNS_ADMIN :
      userPermissionsLocal["allow_user_remaining_to_refund_column"] ? COLUMNS_REFUND_REMAINING : COLUMNS_DEFAULT;

    let columnsReturn = [];
    columnsReturn.push(renderCheckbox());
    columnsReturn.push(renderArrowExpander());

    var index = 0;

    for (const [k, v] of Object.entries(tmpObj)) {
      columnsReturn.push(renderColumn(v[0], k, v[1], data.page[index]));
      index++;
    }

    return columnsReturn;
  }

  useEffect(() => {
    const { transLogData } = transLogReducerData?.transLogData !== null ? transLogReducerData : [];
    if (transLogData) transLogDataLocal = transLogData;
    userPermissionsLocal = user_permissions;
    //columns = populateTXLogColumns();
    manageLoadingState();
  });

  //userPermissionsLocal = useMemo(() => userPermissionsLocal, () => user_permissions);
  //columns = useMemo(() => columns, populateTXLogColumns());

  const reload = async () => {
    const filterBody = transLogReducerData.filterData !== null ? transLogReducerData.filterData
       : {
           orderId: "",
           datetimeFrom: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
           datetimeTo: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
           transactionId: 0
         };

    await getTransData(filterBody, {});
    manageLoadingState();
  }

  return (
    <div className={!isMobile ? "box2" : ""} style={ !isMobile ? {} : {margin:"-10px"} }>
      <div className="tl-table-heading">
        <div style={{display:"inline-block"}}>
          <h4 style={{marginTop:"0px", marginBottom:"0px"}}>
            Transaction Log
            { transactionsInLog() === true ?
              (
                <span style={{fontSize:"14px", paddingLeft:"10px"}}>
                ({(activePage - 1) * itemsCountPerPage + 1} -{" "}{getTotalCount() < itemsCountPerPage ? renderTotalCount() : activePage * itemsCountPerPage} of {renderTotalCount()} results)
                </span>
              ) : (
                <span style={{fontSize:"14px", paddingLeft:"10px"}}>
                (0 results)
                </span>
              )
            }
          </h4>
        </div>
        <div style={{display:"inline-block", width:"4%"}}></div>
        <div style={{display:"inline-block"}}>
          { user_permissions?.allow_user_download_transaction_log ?
              <Download filter={transLogReducerData.filterData} type={"log"} records={getTotalCount()}
                disabled={!transLogReducerData.transLogData || !transLogReducerData.transLogData.page} /> : null
          }
          { user_permissions["allow_user_remaining_to_refund_column"] ?
              <BatchRefund selection={selection} reload={() => reload()} /> : null
          }
          { user_permissions?.allow_user_download_transaction_break_down || user_permissions?.allow_user_download_transaction_break_down_fee
          || user_permissions?.allow_user_download_transaction_break_down_mdr ?
              <Download filter={transLogReducerData.filterData} type={"breakdown"}
                shouldShowCompactButton={true}
                disabled={!transLogReducerData.transLogData || !transLogReducerData.transLogData.page} /> : null
          }
        </div>
      </div>

      { transLogReducerData?.transLogData?.page ? (
        <div>
          { !isMobile ? (
            <div id="txLogTable">
              <ReactTable
               data-matomo-mask
                data={transLogReducerData.transLogData.page}
                columns={populateTXLogColumns(transLogReducerData.transLogData)}
                className="-highlight"
                style={{border: "none"}}
                minRows={0} pageSize={itemsCountPerPage}
                showPagination={false}
                sortable={true}
                multiSort={true}
                resizable={true}
                loading={loading}
                SubComponent={row => <SubDetails row={row}/>}
                getTdProps={(state, rowInfo, column) => {
                  if (rowInfo !== undefined && SELECTABLE_TD.includes(column.Header)) {
                    let id = rowInfo.row.transaction_id;
                    let tmpObj = {...selection};
                    return {
                      ...(column.Header === "Email" ? { "data-matomo-mask": "true" } : {}),
                      onClick: (event, handleOriginal) => {
                        event.stopPropagation();
                        event.nativeEvent.stopImmediatePropagation();
                        if (id in selection) delete tmpObj[id];
                        else tmpObj[id] = {
                          amount: rowInfo.row.remaining_to_refund,
                          currency: rowInfo.row.currency,
                          merchantid: rowInfo.original.merchant_id
                        };
                        setSelection(tmpObj);
                      }
                    };
                  } else return {}
                }}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo !== undefined) return {
                    className: classnames({
                      "table-row": true,
                      "onclick-bgcolor-transition": true,
                      "onclick-scale": true,
                      "row-pending": ["In progress","Accepted","Unknown"].includes(rowInfo.row.transaction_status),
                      "row-success": rowInfo.row.transaction_status === "Successful",
                      "row-failed": rowInfo.row.transaction_status === "Failed",
                      //"selected-row": rowInfo.row.transaction_id in selection
                    })
                  };
                  else return {className: classnames({"table-row": true})};
                }}           
              />
            </div>
          ) : (
            <TransactionLogMobile columns={populateTXLogColumns()} data={transLogReducerData.transLogData.page} activePage={activePage} data-matomo-mask/>
          )}
        </div>
      ) : (
        <div className="noDataFound-style" style={{ paddingTop: "5%", paddingBottom: "5%", fontSize: "medium", top:"200px" }}>
          <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
          {" "} There are currently no orders. {" "}
        </div>
      )}

      { transLogReducerData?.transLogData?.page ?
        <div className="txlog-pagination" style={{ textAlign: "center" }}>
          <Pagination
            firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
            lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
            prevPageText={<i className="glyphicon glyphicon-menu-left" />}
            nextPageText={<i className="glyphicon glyphicon-menu-right" />}
            pageRangeDisplayed={10} activePage={activePage ? activePage : 1} itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalRecordsCountTest ? totalRecordsCountTest : 5} onChange={(p) => handlePageChange(p)} />
        </div> : null
      }

      { showNoDataAlert ? <ALERT show={showNoDataAlert} handleClose={() => cancelAlert()} text="No transaction is found to download." /> : null }
      { txDownloadNotAllowed ? <ALERT show={txDownloadNotAllowed} handleClose={() => cancelAlert()} text="Transaction Log download is not allowed." /> : null }
      { refundNotValid ? <ALERT show={refundNotValid} handleClose={() => cancelAlert()} text={"Given amount is not valid!"} /> : null }
    </div>
  );
}

const mapStateToProps = state => ({
  transLogReducerData: state.transLogReducer
});

const mapDispatchToProps = {
  getTransData,
  interfacePaymentCall
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionLogNew)
);