import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Popup from "reactjs-popup";

const contentStyle = { width: "30%" };

const DownloadPasswordModal = ({ isModalOpen, modalMessage, closeModal }) => {
  const handleCopy = () => {
    alert("Password copied to clipboard!");
    closeModal(modalMessage?.password);
  };

  return (
    <Popup
      open={isModalOpen}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      modal
      {...{ contentStyle }}
    >
      {() => (
        <div>
          <div
            style={{
              backgroundColor: "#022128",
              textAlign: "center",
              padding: "5px",
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: "18px",
              }}
            >
              Confirmation Required
            </span>
          </div>
          <div style={{ color: "#112F3A", padding: "5px 7px" }}>
            <p>{`${modalMessage?.message ?? ""}`}</p>
            <p>
              A one-time password has been generated to access the emailed file.
              Before proceeding you must note it down:
            </p>
            <p
              style={{
                color: "#022128",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "15px",
              }}
            >
              {`${modalMessage?.password ?? ""}`}
            </p>
            <p>
              You must close this window before proceeding on the UI. Please
              confirm that you have noted down the password.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              justifyContent: "end",
            }}
          >
            <CopyToClipboard
              text={modalMessage?.password ?? ""}
              onCopy={handleCopy}
            >
              <button
                className="txLogBtn btn"
                style={{
                  width: "auto",
                  padding: "10px",
                  marginRight: "10px",
                }}
              >
                <FontAwesomeIcon icon={faCopy} size="lg" /> Copy Password &
                Close
              </button>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default DownloadPasswordModal;
