import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "react-table-6/react-table.css";
import { getPaymentLinkList } from "../../../actions/paymentLinkAction";
import PaymentLinkForm from "./PaymentLinkForm";
import * as helper from "../../general/core/helper";
import NumberFormat from "react-number-format";
import ReactTable from "react-table-6";
import ReactTooltip from "react-tooltip";
import Popup from "reactjs-popup";
import PaymentLinkDetails2 from "./PaymentLinkDetails2";
import PaymentLinkStatus from "./PaymentLinkStatus";

class PaymentLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentLinkListData: [],
      totalRecordsCount: 1,
      activePage: 1,
      errorText:'',
      errorGeneratePL:'',
      isPaymentLinkNewOpen:false,
      isPaymentLinkDetailsOpen:false,
      isPaymentLinkNewFading : null,
      isPaymentLinkDetailsFading : null,
      selectedLinkID:"",
    };

    this.renderPaymentLinkColumns = this.renderPaymentLinkColumns.bind(this);
    this.renderColumnCell = this.renderColumnCell.bind(this);
  }

  componentDidMount() {
    this.requestPaymentLinkList();
  }

  async requestPaymentLinkList() {
    await this.props.getPaymentLinkList({});
    
    const paymentLinkListData = this.props.paymentLinkList
     ? this.props.paymentLinkList.paymentLinkListData
     : {};

    var totalRecordsCount = 0
    if (paymentLinkListData !== null) {
      if ('length' in paymentLinkListData) {
        totalRecordsCount = paymentLinkListData.length;
      }

      if (paymentLinkListData.error_message && paymentLinkListData.error_message !== "") {
        //this.getErrorText("tableError", paymentLinkListData.error_message);
        return;
      }

      if (paymentLinkListData.errors && paymentLinkListData.errors !== "0") {
        //this.getErrorText("tableError", "Cannot load Payment Methods. Error code: " + paymentLinkListData.errors);
        return;
      }

      this.setState(state => ({
        ...state,
        paymentLinkListData: paymentLinkListData,
        totalRecordsCount: totalRecordsCount,
      }));
    }
  }
 
  renderColumnCell(row, key) {
    if (key === "amount") {
      return (
        <div style={{padding:"15px 0px"}}>
          <NumberFormat
            value={row.value}
            thousandSeparator={true}
            decimalScale={2}
            displayType={"text"}
            fixedDecimalScale={true}
            isNumericString={false}/>
        </div>
      );
    } else if (key === "linkid") {
      return (
        <input
        className="linkIDLink"
        onClick={e => {
          this.setState({
            isPaymentLinkDetailsOpen: true,
            isPaymentLinkDetailsFading : setTimeout(() => {this.setState({isPaymentLinkDetailsFading:null})}, 100),
            selectedLinkID: row.value
          })
        }}
        name="linkid"
        type="Button"
        defaultValue={row.value}
        disabled={row.original.status !== 1}/>
      );
    } else if (key === "transactionid") {
      return (
        <input
          className="linkIDLink"
          onClick={e => {e.preventDefault(); helper.renderTransDetails(row.original.transactionid);}}
          name="transactionid"
          type="Button"
          defaultValue={row.value}/>
      );
    } else if (key === "status") {
      return <PaymentLinkStatus status={parseInt(row.value)}/>
    } else 
      return <div style={{padding:"15px 0px"}}>{row.value}</div>
  }

  renderPaymentLinkColumn(label, key, minWidth) {
    return {
      Header: label,
      accessor: key,
      Cell: row => this.renderColumnCell(row, key),
      minWidth: minWidth,
      headerStyle: {
        textAlign: "center",
        fontWeight: "600", 
        padding: "10px 5px"
      },
      style: {
        whiteSpace: "unset",
        textAlign: "center",
        color: "#235669",
      }
    };
  }

  renderPaymentLinkColumns() {
    return [
      this.renderPaymentLinkColumn("Link ID", "linkid", 50),
      this.renderPaymentLinkColumn("Transaction ID", "transactionid", 80),
      this.renderPaymentLinkColumn("Merchant ID", "merchant", 100),
      this.renderPaymentLinkColumn("Order ID", "order", 100),
      this.renderPaymentLinkColumn("Currency", "currency", 50),
      this.renderPaymentLinkColumn("Amount", "amount", 80),
      this.renderPaymentLinkColumn("Status", "status", 70),
    ];
  }

  plNewToggle = () => {
    clearTimeout(this.state.isPaymentLinkNewFading);
    this.setState({
      isPaymentLinkNewOpen : !this.state.isPaymentLinkNewOpen,
      isPaymentLinkNewFading : setTimeout(() => {this.setState({isPaymentLinkNewFading:null})}, 80)
    });
  };

  plDetailsToggle = () => {
    clearTimeout(this.state.isPaymentLinkDetailsFading);
    this.setState({
      isPaymentLinkDetailsOpen : !this.state.isPaymentLinkDetailsOpen,
      isPaymentLinkDetailsFading : setTimeout(() => {this.setState({isPaymentLinkDetailsFading:null})}, 80)
    });
  };

  render() {
    return (
      <div id="paymentLinkTab">
        <div id="paymentLinkHeader" className="tab-pane active box-body form-inline filter-search-tab" role="tabpanel" aria-labelledby="search-tab">
          <Popup modal open={this.state.isPaymentLinkNewOpen || this.state.isPaymentLinkNewFading !== null} closeOnDocumentClick={false}
            className = { `transition ${(this.state.isPaymentLinkNewOpen) ? 'paymentLinkFadeIn in' : 'paymentLinkFadeOut out'}` }>
              <PaymentLinkForm
                serverFilterData = {this.props.serverFilterData !== undefined ? this.props.serverFilterData : {}}
                closePopup={this.plNewToggle} userPermissions={this.props.user_permissions}/>
          </Popup>
          <button id="newPaymentLinkBtn" className="primaryBtn2" onClick={ this.plNewToggle }>Create New Link</button>

          <Popup modal open={this.state.isPaymentLinkDetailsOpen || this.state.isPaymentLinkDetailsFading !== null} closeOnDocumentClick={false}
            className = { `transition ${(this.state.isPaymentLinkDetailsOpen) ? 'paymentLinkFadeIn in' : 'paymentLinkFadeOut out'}` }>
              <PaymentLinkDetails2
                selectedLinkID={this.state.selectedLinkID}
                closePopup={this.plDetailsToggle}/>
          </Popup>
        </div>

        <div id="plContent" className="box2 filter-chips">
          <div id="plTableHeader" className="tl-table-heading">
            <h4>Payment Link <span style={{ paddingLeft:"10px"}}>( {this.state.totalRecordsCount} results )</span></h4>
          </div>

          { Array.isArray(this.state.paymentLinkListData) ?
            <ReactTable loading={false} minRows={5} defaultPageSize={20} showPagination={true} sortable={true} multiSort={true} resizable={true} defaultSortDesc={true}
              className="-highlight"
              style={{border: "none"}}
              data={this.state.paymentLinkListData}
              columns={this.renderPaymentLinkColumns()}
              rowsText={'rows per page'}
              renderPageJump={
                ({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => {
                  return null;
                }
              }
            /> : (
            <div className="noDataFound-style" style={{ paddingTop: "5%", paddingBottom: "5%", fontSize: "medium", top:"300px" }}>
              {" "} No records. {" "}
            </div>
          )}

          <ReactTooltip id="err-code" place="right" type="dark" effect="float">{}</ReactTooltip>
        </div>
      </div>
    );
  }
}
 
const mapStateToProps = (state) => ({  
  paymentLink:      state.paymentLinkReducer,
  paymentLinkList:  state.paymentLinkReducer,  
});

const mapDispatchToProps = {
  getPaymentLinkList
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentLink)
);