import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPOSTransactions } from "../../../actions/posTransactionsAction";
import moment from "moment-timezone";
import ReactTable from "react-table-6";
import NumberFormat from "react-number-format";
import TransactionIDCell from "../transactionLog/txlogTableCells/TransactionIDCell";
import Pagination from "react-js-pagination";
import _ from "lodash";
import {LoadingScreen} from "../../general/common/LoadingScreen";
import {POS_TRANSACTIONS_COLUMNS_KEYS, POS_TRANSACTIONS_DATA_TABLE_COLUMNS, POS_TRANSACTION_FILTERS} from "../../../lib/constants/posTransactions";
import DateCell from "../transactionLog/txlogTableCells/DateCell";
import StatusCell from "../transactionLog/txlogTableCells/StatusCell";
import BINCountryCell from "../transactionLog/txlogTableCells/BINCountryCell";
import CCNCell from "../transactionLog/txlogTableCells/CCNCell";
import styles from "./POSTransactions.module.css";
import {CustomFilter} from "../../general/common/CustomFilter";
import {DATE15MIN} from "../../../lib/constants/filter";
import Download from "../transactionLog/Download";
import ErrorCodeCell from "../transactionLog/txlogTableCells/ErrorCodeCell";
import TxTypeCell from "./posLogTableCells/TxTypeCell";

const initialFilterData = {
    dateFrom: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    dateTo: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
    datetimeFrom: moment()
      .startOf("month")
      .format("YYYY-MM-DDTHH:mm:ss"),
    datetimeTo: moment()
      .endOf("month")
      .format("YYYY-MM-DDTHH:mm:ss"),
    transactionId: 0,
    txLogParams: { txcount: [1]},
};

const POSTransactions = ({getPOSTransactions, posTransactionsReducer, serverFilterData, showLoadingScreen, userPermissions}) => {
	const [loading, setLoading] = useState(true);
	const [activePage, setActivePage] = useState(1);
	const [filters, setFilters] = useState(initialFilterData);
	const [generalInterval, setGeneralInterval] = useState([]);

    const [totalRecordsCount, setTotalRecordsCount] = useState(0);
    const [pagesTotalCount, setPagesTotalCount] = useState(0);

    const pageSize = 100;

    const getPOSTransactionsData = () => {
        return posTransactionsReducer.posTransactionsData;
    }

    const fetchPOSTransactions = async (filters) => {
        try {
            setLoading(true);
            await getPOSTransactions(filters);
            setLoading(false);
        }
        catch(error) {
            console.error("Error on fetching POS transactions", error)
        }
    }

    const handlePageChange = async pageNumber => {
        const transactionLogParams = {
            pageIntervalStart: [ generalInterval?.[0] ],
            pageIntervalEnd: [ generalInterval?.[1] ],
            pageId: [pageNumber]
        }

        if (filters?.txLogParams?.txcount) {
            delete filters.txLogParams.txcount;
        }

        const updatedFilters = {...filters, txLogParams: {...filters.txLogParams, ...transactionLogParams}};
        await fetchPOSTransactions(updatedFilters);
        setFilters(updatedFilters);
        setActivePage(pageNumber);
    };
    
    const renderCell = (row, key) => {
        if (!row.value) return <></>;
        
        switch (key) {
            case POS_TRANSACTIONS_COLUMNS_KEYS.AMOUNT:
            case "counter":
                return (
                    <div style={{ margin: "10px 0px" }}>
                        <NumberFormat value={row.value} thousandSeparator={true} decimalScale={2} displayType={"text"} fixedDecimalScale={true} />
                    </div>
                );
            case POS_TRANSACTIONS_COLUMNS_KEYS.TRANSACTION_ID:
                return <TransactionIDCell value={row.value} original={row.original} isCounterEnabled={false} />;
            case POS_TRANSACTIONS_COLUMNS_KEYS.DATE:
                return <DateCell original={row.original} />;
            case POS_TRANSACTIONS_COLUMNS_KEYS.ERROR_CODE:
                return <ErrorCodeCell original={row.original} />;
            case POS_TRANSACTIONS_COLUMNS_KEYS.TRANSACTION_STATUS:
                return <StatusCell value={row.value} original={row.original} />;
            case POS_TRANSACTIONS_COLUMNS_KEYS.BIN_COUNTRY:
                return <BINCountryCell value={row.value} />;
            case POS_TRANSACTIONS_COLUMNS_KEYS.PAN:
                if (row.value && row.value !== "N/A") {
                    return <CCNCell value={row.value} />;
                }
                break;
            case POS_TRANSACTIONS_COLUMNS_KEYS.TRANSACTION_TYPE:
               return <TxTypeCell value={row.original[key]} />
            default:
                if (key && row.value && row.value === "Shoppayment-Init") {
                    return <p style={{ margin: "10px 0px" }}>Init</p>;
                } else if (key && row.value) {
                    return <div className='vertical-center'><p className='margin-0'>{row.value}</p></div>;
                } else {
                    return null;
                }
        }
    };    
    
    const populateTableColumns = () => {
        return Object.entries(POS_TRANSACTIONS_DATA_TABLE_COLUMNS).map(([key, value]) => {
            const [label, minWidth] = value;

            return {
                Header: label,
                accessor: key,
                Cell: row => renderCell(row, key),
                minWidth: minWidth,
                headerStyle: { color: "rgb(17 47 58)", textAlign: "center", fontWeight: "700", backgroundColor: "white" },
                style: { whiteSpace: "unset", textAlign: "center", color: "rgb(17 47 58)", fontWeight: "500", position: 'relative' },
            };
        });
    }
    
    const getTrProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            const status = rowInfo.row._original.transaction_status;
            let className = ''; // Initialize class name
            switch (status) {
                case 'Failed':
                    className = 'row-failed'; // Use class for failed rows
                    break;
                case 'Successful':
                    className = 'row-success'; // Use class for successful rows
                    break;
                case 'In progress':
                case 'Accepted':
                case 'Unknown':
                    className = 'row-pending'; // Use class for pending and related statuses
                    break;
                default:
                    className = ''; // No additional class for default
                    break;
            }
            return {
                className: className
            };
        }
        return {}; // Return empty for rows without data
    };

    const onUpdateFilters = (updatedFilters, updateTable = true) => {
        const newFilters = Object.assign(filters, {
            dateFrom: updatedFilters.dateFrom,
            dateTo: updatedFilters.dateTo,
            datetimeFrom: updatedFilters.datetimeFrom,
            datetimeTo: updatedFilters.datetimeTo,
            records_per_page: pageSize,
            txLogParams: {txcount: [1], ...updatedFilters.filters}
        });
        if (updateTable) {
            fetchPOSTransactions(newFilters);
        }
        setFilters(newFilters);
        setActivePage(1);
    }

    useEffect(() => {
        if (_.isNumber(getPOSTransactionsData()?.totalRecordsCount)) {
            setTotalRecordsCount(getPOSTransactionsData().totalRecordsCount);
        }
        if (_.isNumber(getPOSTransactionsData()?.pagesTotalCount)) {
            setPagesTotalCount(getPOSTransactionsData().pagesTotalCount);
        }
        if (_.isArray(getPOSTransactionsData()?.general_interval)) {
            setGeneralInterval(getPOSTransactionsData().general_interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPOSTransactionsData()?.page]);

    return (
        <div className={styles.posTransactionsWrapper}>
            {
                <div className={styles.posTransactionsFilters}>
                    <CustomFilter
                        isPosAdvancedFilter={true}
                        dateFilter={DATE15MIN}
                        filters={POS_TRANSACTION_FILTERS}
                        serverFilterData={serverFilterData}
                        updateFilters={onUpdateFilters}
                        showLoadingScreen={showLoadingScreen}
                        isAdvancedSearchEnabled={true}
                        isAdmin= {userPermissions.is_admin}
                    >
                    </CustomFilter>
                </div>
            }
            <div className={styles.posTransactionsData}>
                <div className={styles.tableHeader}>
                    <h4> POS Transactions </h4>
                    <span className={styles.logCount}> ({totalRecordsCount ?? 0} results) </span>

                    { (userPermissions && userPermissions.allow_user_download_transaction_log) &&
			         	<Download
                            isPOSTransaction={true}
                            filter={filters}
                            type={"log"}
                            records={totalRecordsCount}
                            disabled={!getPOSTransactionsData()?.page} />
                    }
                </div>
                {
                    loading && !getPOSTransactionsData()?.page ?
                        <LoadingScreen />
                    :
                        ( getPOSTransactionsData()?.page ? (
                            <div className={styles.tableBodyWrapper}>
                                <ReactTable
                                    data={getPOSTransactionsData().page}
                                    columns={populateTableColumns(getPOSTransactionsData())}
                                    className="-highlight -striped"
                                    style={{border: "none"}}
                                    minRows={0}
                                    pageSize={pageSize}
                                    showPagination={false}
                                    sortable={true}
                                    multiSort={true}
                                    resizable={true}
                                    loading={loading}
                                    getTrProps={getTrProps}
                                />
                            </div>
                    ) : (
                        <div className={styles.emptyData}>
                            <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
                            <span>There are currently no orders. {" "}</span>
                        </div>
                    ))
                }

                { getPOSTransactionsData()?.page ?
                    <div className="txlog-pagination" style={{ textAlign: "center" }}>
                    <Pagination
                        firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
                        lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
                        prevPageText={<i className="glyphicon glyphicon-menu-left" />}
                        nextPageText={<i className="glyphicon glyphicon-menu-right" />}
                        activePage={activePage ? activePage : 1}
                        itemsCountPerPage={totalRecordsCount / pagesTotalCount}
                        totalItemsCount={totalRecordsCount}
                        onChange={(page) => handlePageChange(page)} />
                    </div> : null
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    posTransactionsReducer: state.posTransactionsReducer,
});

const mapDispatchToProps = {
    getPOSTransactions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(POSTransactions)
);