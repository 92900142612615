import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { getData } from "../../../actions/chartsDataAction";
import { getPermissions } from "../../../actions/dashboardAction";
import { getTransData, filterBodyData } from "../../../actions/transLogAction";
import { getFinanceData } from "../../../actions/financeAction";
import Master from "../../layouts/dashboard";
import Popup from "reactjs-popup";
import * as routes from "../../../lib/constants/routes";
import { LAYOUTS } from "../../../lib/constants/layouts";
import {
  GridTop,
  BarChart,
  VerticalBar,
  PieChart,
  PieChartActiveShape,
  AreaChart,
  LineChart,
  TableChart,
  TableCharts,
  ComposedChart,
  FinanceTeil,
  FiguresTop
} from "../../general/charts";
import { Filter } from "../../general/common/Filter";
import TransactionLog from "../../screen/transactionLog/TransactionLog";
import TransactionLogNew from "../../screen/transactionLog/TransactionLogNew";
import ChargebackAlerts from "../../screen/chargebackAlerts/ChargebackAlerts";
import Finance from "../../screen/finance/Finance";
import VirtualTerminal from "../../screen/transactionLog/VirtualTerminal";
import PaymentLink from "../../screen/paymentLink/PaymentLink";
import EcommercePlugin from "../../screen/ecommerce/plugin";
import Admin from "../../screen/admin/Admin";
import MerchantBoard from "../../screen/merchantBoard/MerchantBoard";
import PartnerMerchants from "../../screen/partnerMerchants/PartnerMerchants";
import { LoadingScreen } from "../../general/common/LoadingScreen";
import { ScaleLoader, BarLoader } from "react-spinners";
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import PaymentLinkDetails from "../../screen/paymentLink/PaymentLinkDetails";
import axios from "axios";
import { IntercomProvider } from 'react-use-intercom';
import POSTransactions from "../../screen/posTransactions/POSTransactions";
import {setCustomUserId} from "../../../matomoScript";

const modalStyle = {
  content: {
    top: '500px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: "none",
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

class Generic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: null,
      chartIds: [],
      tabData: null,
      dateTo: null,
      dateFrom: null,
      datetimeFrom: null,
      datetimeTo: null,
      search: null,
      path: props.match.params.id,
      body: {
        dateFrom: moment()
          .startOf("month")
          .format("YYYY-MM-DD"), // data body which will be used to render the pages
        dateTo: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        datetimeFrom: moment()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ss"),
        datetimeTo: moment()
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ss"),
        filters: {},
        chartIds: 1,
      },

      transLogLoaded: false,
      loadingActive: true,
      onTransLogPage: false,

      filterChanged: false,
      chartDataObj: null,

      modalIsOpen: false,
      contentURL: "",
      fraud_report_currency: "USD",
      hasMounted: false,
    };

    this.transLogRequest = null;
    this.cancelToken = axios.CancelToken.source();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setModalContentURL = this.setModalContentURL.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  setModalContentURL(url) {
    this.setState({ contentURL: url });
  }

  async componentDidMount() {
    // server request when permission is empty.
    // passed on over login page, which requested permissions.
    // mostly executed when page is reloaded and data passed.
    if (!this.props.location.permissions) {
      await this.props.getPermissions();
    }

    const path = this.props.match.params.id
      ? this.props.match.params.id
      : "dashTab";
    const tabData = this.props.sideBarData.permission
      ? this.props.sideBarData.permission.pages[path]
      : null;

    let chartIds = [];
    if (tabData && tabData.gElements) {
      Object.keys(tabData.gElements).map((key, i) => {
        return chartIds.push(tabData.gElements[key].chart_id);
      });
    }

    if (chartIds.length > 0) {
      this.setState({ chartIds, tabData });
    }

    let id = this.props.sideBarData?.data?.access_data?.id;
    let username = this.props.sideBarData?.data?.access_data?.username;
    let email = this.props.sideBarData?.data?.access_data?.email;
    let companyArr = this.props.sideBarData?.data?.filters?.merchant_company_id;
    let company = this.props.sideBarData?.data?.filters?.merchant_company_id?.toString();

    setCustomUserId(id);

    if (this.props.sideBarData.data.intercom.enabled === "1") {
      window.Intercom('boot', {
        app_id: this.props.sideBarData?.data?.intercom?.app_id,
        name: username,
        email: email,
        user_id: id,
        user_hash: this.props.sideBarData?.data?.intercom?.user_hash,
        company: {
          company_id: companyArr[0] + id,
          name: company,
        }
      });
    }

    if (companyArr !== undefined && id !== undefined) {
      localStorage.setItem("merchant_id", id);
    }

    this.setState({ hasMounted: true }, () => {
      this.getData(this.state.body);
    });
  }

  showLoadingScreen = v => {
    this.setState({ loadingActive: v });
  };

  changeFilter = value => {
    this.setState({ filterChanged: value });
  };

  getTransactionTableData = filter_values => {
    let requestBody = {
      offset: 0,
      limit: 5
    };

    let filterBody = {
      //orderId: "",
      orderId:
        filter_values !== undefined && filter_values.orderID
          ? filter_values.orderID
          : "",
      dateFrom:
        filter_values !== undefined && filter_values.dateFrom
          ? filter_values.dateFrom
          : // : moment(new Date()).format("YYYY-MM-DD"),
          moment(new Date()).format("YYYY-MM-DD"),
      dateTo:
        filter_values !== undefined && filter_values.dateTo
          ? filter_values.dateTo
          : moment(new Date()).format("YYYY-MM-DD"),

      datetimeFrom:
        filter_values !== undefined && filter_values.datetimeFrom
          ? filter_values.datetimeFrom
          : moment(new Date())
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),

      datetimeTo:
        filter_values !== undefined && filter_values.datetimeTo
          ? filter_values.datetimeTo
          : moment(new Date())
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),

      //transactionId: 0,
      transactionId:
        filter_values !== undefined && filter_values.transactionID
          ? filter_values.transactionID
          : 0,

      txLogParams:
        filter_values !== undefined && filter_values.filters
          ? filter_values.filters
          : ""
    };

   	// Cancel previous ongoing request when a request is fired.
   	this.cancelToken.cancel();
   	this.cancelToken = axios.CancelToken.source();

    this.transLogRequest = this.props.getTransData(filterBody, requestBody, this.cancelToken);
    this.props.filterBodyData(filterBody);
  };

  // Function to update the data body. Called by filter.
  updateBody = filter_values => {
    this.setState({ body: filter_values });
  };

  // Function to update the fraud report. Called by filter.
  updateFraudReport = currency => {
    this.setState(
      {
        fraud_report_currency:  currency
      },
      () => this.getData(this.state.body)
    );

  };

  getFinanceData = filter_values => {
    let filterBody = {
      orderId: "",
      dateFrom:
        filter_values !== undefined && filter_values.dateFrom
          ? filter_values.dateFrom
          : // : moment(new Date()).format("YYYY-MM-DD"),
          moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
      dateTo:
        filter_values !== undefined && filter_values.dateTo
          ? filter_values.dateTo
          : moment()
            .endOf("month")
            .format("YYYY-MM-DD"),

      datetimeFrom:
        filter_values !== undefined && filter_values.datetimeFrom
          ? filter_values.datetimeFrom
          : moment()
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm:ss"),

      datetimeTo:
        filter_values !== undefined && filter_values.datetimeTo
          ? filter_values.datetimeTo
          : moment()
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm:ss"),

      filters:
        filter_values !== undefined && filter_values.filters
          ? filter_values.filters
          : []
    };
    this.props.getFinanceData(filterBody);
  };

  getData = filter_values => {
    let activeTab = this.props.location.pathname;

    switch (activeTab) {
      case routes.TRANS_LOG:
        if (filter_values && filter_values.filters.fraud_report_currency) {
          delete filter_values.filters.fraud_report_currency;
        }
        if (!this.state.onTransLogPage) {
          // Set date to today as soon transLogPage is called.
          filter_values.dateFrom = moment().tz("Europe/Berlin")
            .subtract(15, 'minutes')
            .format("YYYY-MM-DD");
          filter_values.dateTo = moment().tz("Europe/Berlin")
            .format("YYYY-MM-DD");

          filter_values.datetimeFrom = moment().tz("Europe/Berlin")
            .subtract(15, 'minutes')
            .format("YYYY-MM-DDTHH:mm:ss");
          filter_values.datetimeTo = moment().tz("Europe/Berlin")
            .format("YYYY-MM-DDTHH:mm:ss");

          this.setState({ onTransLogPage: true });
        }
        filter_values.filters.txcount = [1];
        this.getTransactionTableData(filter_values);
        break;

      case routes.FINANCE_DATA:
        if (filter_values && filter_values.filters.fraud_report_currency) {
          delete filter_values.filters.fraud_report_currency;
        }
        if (filter_values && filter_values.filters.txcount) {
          delete filter_values.filters.txcount;
          delete filter_values.filters.pageIntervalStart;
          delete filter_values.filters.pageIntervalEnd;
        }
        if (this.state.onTransLogPage) {
          // Set date to this month when leaving transLogPage.
          filter_values.dateFrom = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
          filter_values.dateTo = moment()
            .endOf("month")
            .format("YYYY-MM-DD");

          filter_values.datetimeFrom = moment()
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm:ss");
          filter_values.datetimeTo = moment()
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm:ss");

          this.setState({ onTransLogPage: false });
        }
        this.getFinanceData(filter_values);
        break;

      case routes.ECOMMERCE_PLUGINS:
      break;
      case routes.CHARGEBACK_ALERTS:
        break;

      default:
        if (filter_values && filter_values.filters) {
          let filterFields = [
            "pm_name",
            "ps_name",
            "merchant_name",
            "merchant_company_id",
            "card_brand",
            "currency",
            "bin_country",
            "bin_region",
            "ip_country",
            "acquirer_mid"
          ];

          // Delete all filter values which are not relevant for tabs other than translog.
          Object.keys(filter_values.filters).map(key => {
            if (!filterFields.includes(key)) delete filter_values.filters[key];
            return null;
          });
        }

        if (this.state.onTransLogPage) {
          // Set date to this month when leaving transLogPage.
          filter_values.dateFrom = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
          filter_values.dateTo = moment()
            .endOf("month")
            .format("YYYY-MM-DD");

          filter_values.datetimeFrom = moment()
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm:ss");
          filter_values.datetimeTo = moment()
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm:ss");
          this.setState({ onTransLogPage: false });
        }

        // if this is Fraud tab, enter fraud_report_currency to filters
        if ( activeTab === routes.FRAUD_TAB )
        {
            if (
                filter_values &&
                filter_values?.filterData?.fraud_transactions
            ) {
                delete filter_values?.filterData?.fraud_transactions;
                delete filter_values?.filters?.fraud_transactions;
            }
            let fraud_currency = [];
            fraud_currency[0] = this.state.fraud_report_currency;
            filter_values.filters.fraud_report_currency = fraud_currency;
        }

        this.setState(
          {
            body: {
              dateFrom: filter_values.dateFrom,
              dateTo: filter_values.dateTo,
              datetimeFrom: filter_values.datetimeFrom,
              datetimeTo: filter_values.datetimeTo,
              filters: filter_values.filters,
              chartIds: this.state.chartIds
            }
          },
          () => this.props.getData(this.state.body)
        );
        break;
    }
  };

  renderStaticPages = () => {
    let activeTab = this.props.location.pathname;

    //check if this is for chart details
    if (this.props.match.params.chartDetailId) {
      activeTab = routes.CHART_DETAILS;
    }

    let user_permissions = this.props.sideBarData.data
      ? this.props.sideBarData.data.user_permissions
      : [];

    let backoffice_functions = this.props.sideBarData.data
      ? this.props.sideBarData.data.backoffice_functions
      : [];

    switch (activeTab) {
      case routes.TRANS_LOG: {
        return (
          <TransactionLogNew
            showLoadingScreen={this.showLoadingScreen}
            user_permissions={user_permissions}
          />
        );
      }
      case routes.POS_TRANSACTIONS: {
        return (
          <POSTransactions
            showLoadingScreen={this.showLoadingScreen}
            userPermissions={user_permissions}
            serverFilterData={this.props.sideBarData.data?.filters || []}
          />
        );
      }
      case routes.CHARGEBACK_ALERTS: {
        return (
          <ChargebackAlerts
            userPermissions={user_permissions}
            backOfficePermission={backoffice_functions}
          />
        );
      }
      case routes.PARTNER_MERCHANTS:
        return <PartnerMerchants />;
      case routes.FINANCE_DATA:
        return <Finance user_permissions={user_permissions} />;
      case routes.VIRTUAL_TERM: {
        let filter_from_server = this.props.sideBarData.data
          ? this.props.sideBarData.data.filters
          : [];

        return (
          <VirtualTerminal
            serverFilterData={filter_from_server}
            user_permissions={user_permissions}
          />
        );
      }
      case routes.PAYMENT_LI_DETAILS: {
        let filter_from_server = this.props.sideBarData.data
          ? this.props.sideBarData.data.filters
          : [];

        return (
          <PaymentLinkDetails
            serverFilterData={filter_from_server}
            user_permissions={user_permissions}
          />
        );
      }
      case routes.PAYMENT_LI: {
        let filter_from_server = this.props.sideBarData.data
          ? this.props.sideBarData.data.filters
          : [];

        return (
          <PaymentLink
            serverFilterData={filter_from_server}
            user_permissions={user_permissions}
          />
        );
      }
      case routes.ECOMMERCE_PLUGINS: {
        return <EcommercePlugin user_permissions={user_permissions} />;
      }
      case routes.CHART_DETAILS:
        // update the transactionLogData by update only the filters which in turn should refresh the data
        return <TransactionLog />;
      case routes.ADMIN:
        return <Admin />;
      case routes.MERCHANT_BOARD: {
        let filter_from_server = this.props.sideBarData.data
          ? this.props.sideBarData.data.filters
          : [];
        return <MerchantBoard serverFilterData={filter_from_server} />;
      }
      default:
        return (
          <div className="centered-element">
            {" "}
            <ScaleLoader
              color={"rgb(46, 128, 178)"}
              height={80}
              width={15}
            />{" "}
          </div>
        );
    }
  };

  renderCharts = (data, lable, chart_type, height, width, chart_SizesLable, chart_id) => {
    if (chart_type === 'table' && data && data.constructor === Object && Object.keys(data).length === 0) {
      return (
        //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
        <BarLoader color={"#999"} speedMultiplier={1} />
      );
    }
    if (!data) {
      return (
        //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
        <BarLoader color={"#999"} speedMultiplier={1} />
      );
    } else if (this.state.filterChanged === true) {
      return (
        //<div className="box--skeleton" style={{ textAlign: "center", backgroundColor: "#eee", height:"100%" }} />
        <BarLoader color={"#999"} speedMultiplier={1} />
      );
    } else {
      switch (chart_type) {
        case "bar":
          return data ? (
            <BarChart
              data={data}
              height={height}
              width={width}
              currentPath={this.props.location.pathname}
              label={lable}
              chartId={chart_id}

            />
          ) : null;
        case "horizontalbar":
          return <VerticalBar data={data} height={height} width={width} label={lable} />;

        case "table": //table_chart
          if (data.data && Array.isArray(data.data) && data.data.length !== 0) {
            // we need different chart_type from db for nested rows
            return (
              <TableChart
                data={data}
                lable={lable}
                height={height}
                width={width}
                key={lable}
              />
            );
          } else if (Array.isArray(data) && data.length !== 0) {
          	if(chart_id === 101) {
              return;
            } else {
              // we need different chart_type from db for nested rows
              return (
                  <TableCharts
                  data={data}
                  lable={lable}
                  height={height}
                  width={width}
                  key={lable}
                  openModal={this.openModal}
                  setModalContentURL={this.setModalContentURL}
                  dateFrom={this.state.body.dateFrom}
                  dateTo={this.state.body.dateTo}
                />
              );
            }
          } else if (Array.isArray(data) && data.length === 0) {
            return (
              <div
                className="chart-box"
                style={{
                  textAlign: "center",
                  paddingTop: "70px",
                  color: "white"
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    color: "white"
                  }}
                >
                  {lable === "Payments Due"
                    ? "No Payments Due"
                    : <h4 className="noDataFound-style">
                        <img src={"/img/icons/block file.svg"} alt="." height="70" /><br/>
                        No data was found!
                      </h4>}
                </span>
              </div>
            );
          }
          break;
        case "area": //area_chart
          return <AreaChart data={data} height={height} width={width} />;

        case "compose": //area_chart
          return <ComposedChart data={data} height={height} width={width} />;

        case "line": //line_chart
          return data ? (
            <LineChart data={data} height={height} width={width} />
          ) : null;

        case "pie": //pie_chart
          return data ? (
            <PieChart data={data} height={height} width={width} />
          ) : null;

        case "pieactive": //pie_chart
          return data ? (
            <PieChartActiveShape data={data} height={height} width={width} />
          ) : null;

        case "grid": //pie_chart
          return data ? (
            <GridTop data={data} height={height} width={width} />
          ) : null;
        case "figures": //pie_chart
          return data ? (
            <FiguresTop data={data} height={height} width={width} />
          ) : null;

        case "finance": //pie_chart
          return data ? (
            <FinanceTeil
              data={data}
              height={height}
              width={width}
              teil={lable}
            />
          ) : null;

        default:
          return (
            <div
              className="box"
              style={{ width: parseInt(width), height: parseInt(height) }}
            >
              <h4
                style={{ textAlign: "left" }}
              >{`Chart Type: ${chart_type}`}</h4>
              <h3 style={{ textAlign: "left" }}>
                {lable}
                {` (size: ${chart_SizesLable})`}
              </h3>{" "}
              <hr />
            </div>
          );
      }
    }
  };

  createGrid(activeTabData, layout) {
    const rows = [];

    // Generate the columns for rows
    let row = 1;
    let column = 0;
    let column_count = LAYOUTS[layout]["row_" + row]["count"];

    const thumbs = Object.keys(activeTabData.gElements).map((key, i) => {
      let chart_SizesLable = activeTabData.gElements[key].chart_size;
      let chart_type = activeTabData.gElements[key].chart_type;
      let chart_id = activeTabData.gElements[key].chart_id;
      let text = activeTabData.gElements[key].text;
      let sub_text = "";

      if (text.includes(" per ")) {
        let words = text.split(" per ");
        text = words[0];
        sub_text = "per " + words[1];
      }

      if (text.includes(" by ")) {
        let words = text.split(" by ");
        text = words[0];
        sub_text = "by " + words[1];
      }

      if (text.includes("(")) {
        let words = text.split("(");
        text = words[0];
        sub_text = "(" + words[1];
      }

      if (text.includes(" (")) {
        let words = text.split(" (");
        text = words[0];
        sub_text = "(" + words[1];
      }

      const chartData = this.props.barChartData.barChartData
        ? this.props.barChartData.barChartData[chart_id]
        : {};

      let details_option = 0;
      let finance_teil = 0;
      let finance_tab_enabled = 0;
      const financePath = {
        pathname: "/generic/financeDataTab"
      };

      if (
        this.props.barChartData.barChartData &&
        this.props.barChartData.barChartData[chart_id + "_details"]
      ) {
        details_option = 1;
      }

      if (chart_id === 84) {
        finance_teil = 1;
      }

      if (this.props?.sideBarData?.permission?.pages["financeDataTab"]?.enabled === 1) {
        finance_tab_enabled = 1;
      }

      if (column_count === column) {
        //row = row + 1;
        if (row < LAYOUTS[layout].rows) {
          row = row + 1;
        }
        column = 0;

        if (LAYOUTS[layout]["row_" + row]) {
          column_count = LAYOUTS[layout]["row_" + row]["count"];
        }
      }

      column = column + 1;

      let fraudReportLevels = ['Acquirer Level', 'Merchant Level'];
      let tableBoxClass = fraudReportLevels.includes(text) ? 'box1' : 'box';
      let tableHeadingClass = fraudReportLevels.includes(text) ? 'box-heading1' : 'box-heading';
      let tableSpanClass = fraudReportLevels.includes(text) ? 'chart-heading1' : 'chart-heading';

      let fraudReportHeading = "Fraud Report"

      let boxHeight = LAYOUTS[layout]["row_" + row] ? parseInt(LAYOUTS[layout]["row_" + row]["columns"]["col_" + column]["height"]) : "100";

      return (
        <div
          key={row + "_" + column}
          className={
            LAYOUTS[layout]["row_" + row]["columns"]["col_" + column]["class"]
          }
        >
          { text === "Acquirer Level" ?
            (
              <div className="box chart-box">
                <h4 className="box-heading">
                  <span className="chart-heading">{fraudReportHeading}</span>
                </h4>
              </div>
            ) : null
          }
          <div className={`chart-box ${tableBoxClass}`}>
            <h4 className={tableHeadingClass}>
              <span className={tableSpanClass}>{text}</span>
              {details_option === 1 ? (
                <span className="modal-heading">
                  <Popup
                    contentStyle={{
                      width: "auto",
                      height: "70%",
                      overflow: "auto",
                      textAlign: "left",
                      zIndex: "200",
                    }}
                    trigger={
                      <img src={"/img/icons/btn-popup.svg"} alt="Info" style={{ cursor: "pointer" }} />
                    }
                    modal
                    closeOnDocumentClick
                  >
                    <span>
                      {text}

                      <div style={{ flexGrow: "1" }}>
                        {activeTabData.gElements[key].chart_type
                          ? this.renderCharts(
                            this.props.barChartData.barChartData[
                              chart_id + "_details"
                            ].data, // data according to filter
                            text,
                            this.props.barChartData.barChartData[
                              chart_id + "_details"
                            ].type,
                            LAYOUTS[layout]["row_" + row]["columns"][
                            "col_" + column
                            ]["height"] * 2,
                            LAYOUTS[layout]["row_" + row]["columns"][
                            "col_" + column
                            ]["width"] * 4,
                            chart_SizesLable,
                            chart_id
                          )
                          : null}
                      </div>
                    </span>
                  </Popup>
                </span>
              ) : (
                  <span style={{float:"right"}}>
                    {finance_teil === 1 && finance_tab_enabled === 1 ? (
                      <Link to={financePath}>
                        <img src={"/img/icons/btn-redirect.svg"} alt="More" style={{ cursor: "pointer" }} />
                      </Link>
                    ) : (
                        ""
                      )}
                  </span>
                )}
                <span className={tableSpanClass} style={{fontSize:"13px", fontStyle:"italic"}}>{" " + sub_text}</span>
            </h4>

            <div id="chart-box" className="chart-box-loader" style={{ flexGrow: "1", height: (chart_type === "table" ? "100%" : boxHeight + "px") }}>
              {activeTabData.gElements[key].chart_type
                ? this.renderCharts(
                  chartData, // data according to filter
                  text,
                  chart_type,
                  LAYOUTS[layout]["row_" + row]["columns"]["col_" + column][
                  "height"
                  ],
                  LAYOUTS[layout]["row_" + row]["columns"]["col_" + column][
                  "width"
                  ],
                  chart_SizesLable,
                  chart_id
                )
                : null}
            </div>

          </div>
        </div>
      );
    });

    // Push a new row for every set of rows
    let slice_rows = 0;
    for (let row = 0; row < LAYOUTS[layout]["rows"]; row += 1) {
      if (
        (activeTabData.layout === 1 || activeTabData.layout === 7) &&
        row === 0
      ) {
        rows.push(
          <div
            key={row + "_row"}
            className="row flex-row"
          >
            {thumbs.slice(
              slice_rows,
              LAYOUTS[layout]["row_" + (row + 1)]["count"] + slice_rows
            )}
          </div>
        );
        slice_rows = LAYOUTS[layout]["row_" + (row + 1)]["count"] + slice_rows;
      } else {
        rows.push(
          <div
            key={row + "_row"}
            className="row"
          >
            {thumbs.slice(
              slice_rows,
              LAYOUTS[layout]["row_" + (row + 1)]["count"] + slice_rows
            )}
          </div>
        );
        slice_rows = LAYOUTS[layout]["row_" + (row + 1)]["count"] + slice_rows;
      }
    }

    // give back an array of our rows composed of columns
    return rows;
  }

  renderData = () => {
    const { path } = this.state;

    let detailsForChart = null;
    if (this.props.match.params.chartDetailId) {
      detailsForChart = this.props.match.params.chartDetailId;
    }

    if (this.props.sideBarData.permission) {
      var activeTabData = this.props.sideBarData.permission.pages[path];
    }

    if (activeTabData?.gElements && !activeTabData.static && detailsForChart === null) {
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingBottom: "20px"
          }}
        >
          {this.createGrid(activeTabData, activeTabData.layout - 1)}
        </div>
      );
    } else {
      return this.renderStaticPages();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { path: statePath } = prevState;
    const path = nextProps.match.params.id
      ? nextProps.match.params.id
      : "dashboardTab";
    let obj = {};

    if (path !== statePath) {
      const tabData = nextProps.sideBarData.permission
        ? nextProps.sideBarData.permission.pages[path]
        : null;

      let chartIds = [];
      if (tabData && tabData.gElements) {
        Object.keys(tabData.gElements).map((key, i) => {
          return chartIds.push(tabData.gElements[key].chart_id);
        });
      }

      obj = {
        tabData: tabData,
        chartIds: chartIds,
        path: path,
        toGetData: true
      };

      return obj;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { toGetData, hasMounted} = this.state;

    // Closes chargeback/fraud list modal if user changes tabs and the previous tab was either chargeback or fraud.
    let prevPath = prevState.path;
    let nextPath = this.state.path;
    if ((prevPath === "cbkTab" && nextPath !== "cbkTab") || (prevPath === "fraudTab" && nextPath !== "fraudTab")) {
      if (this.state.modalIsOpen) this.closeModal();
    }

    let activeTab = this.props.location.pathname;
    if (activeTab === routes.FINANCE_DATA || activeTab === routes.TRANS_LOG || activeTab === routes.CHARGEBACK_ALERTS) {
      if (toGetData === undefined) {
        this.setState({ toGetData: true });
      }
    }

    if (toGetData && hasMounted &&  activeTab !== prevProps.location.pathname) {
      this.setState({ toGetData: false }, () => this.getData(this.state.body));
    }

    if (this.props.barChartData.barChartData) {
      if (this.props.barChartData.barChartData !== this.state.chartDataObj) {
        this.setState({
          chartDataObj: this.props.barChartData.barChartData,
          filterChanged: false
        });
      }
    }
  }

  render() {
    const { pathname } = this.props.location;

    let filter_from_server = this.props.sideBarData.data
      ? this.props.sideBarData.data.filters
      : [];

    let containerStyle = {};
    if (pathname === "/generic/paymentLinkTab") {
      containerStyle = { paddingLeft: "20px", paddingRight: "20px" };
    } else if (
      pathname !== routes.CHARGEBACK_ALERTS &&
      pathname !== routes.POS_TRANSACTIONS
    ) {
      containerStyle = {
        paddingTop: "30px",
        paddingLeft: "30px",
        paddingRight: "20px",
      };
    }

    return (
      <div>
        <IntercomProvider>
          <Master key="layout" pathname={pathname} />

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            closeTimeoutMS={300}
            onRequestClose={this.closeModal}
            style={modalStyle}
            contentLabel="Example Modal"
          >
            <div style={{ position: "relative" }}>
              <iframe
                title="iframe"
                src={this.state.contentURL}
                width="830"
                height="600"
                style={{ border: "none", width: "50vw" }}
              ></iframe>
              <Button
                onClick={this.closeModal}
                className="generic-modal-cancel-btn collapsed"
                data-toggle="collapse"
                data-target={"#vtInfoBoxCollapse"}
                aria-expanded="true"
                aria-controls={"vtInfoBoxCollapse"}
              >
                <i className="fa fa-times fa-lg"></i>
              </Button>
            </div>
          </Modal>

          <div
            key="layout_div"
            className="content-wrapper"
            style={{ paddingTop: "0px" }}
          >
            <section
              className="content"
              style={{ height: "100vh", paddingTop: "35px" }}
            >
              <div className="row" style={{ backgroundColor: "white" }}>
                <div
                  className="main-filter"
                  style={{ backgroundColor: "#F4F6FB" }}
                >
                  {/* FILTERS */}
                  {this.props.sideBarData.permission?.pages[this.state.path]
                    ?.search !== 0 &&
                  pathname !== routes.VIRTUAL_TERM &&
                  pathname !== routes.ADMIN &&
                  pathname !== routes.PAYMENT_LI &&
                  pathname !== routes.ECOMMERCE_PLUGINS &&
                  pathname !== routes.TRUSTLY_BALANCES &&
                  pathname !== routes.MERCHANT_BOARD &&
                  pathname !== routes.CHARGEBACK_ALERTS &&
                  pathname !== routes.POS_TRANSACTIONS &&
                  pathname !== routes.PARTNER_MERCHANTS ? (
                    <Filter
                      getData={this.getData}
                      hasMounted={this.state.hasMounted}
                      serverFilterData={filter_from_server}
                      updateBody={this.updateBody}
                      currentPath={pathname}
                      showLoadingScreen={this.showLoadingScreen}
                      activeTab={pathname}
                      onTransLogPage={this.state.onTransLogPage}
                      changeFilter={this.changeFilter}
                      updateFraudReport={this.updateFraudReport}
                    />
                  ) : null}

                  {/* TRANSACTION LOG LOADING SCREEN */}
                  {this.state.transLogLoaded === false &&
                  this.state.loadingActive &&
                  pathname === routes.TRANS_LOG ? (
                    <LoadingScreen />
                  ) : null}
                </div>
                {/* CHARTS CONTAINER */}
                <div className="charts-div" style={containerStyle}>
                  {this.renderData()}
                </div>
              </div>
            </section>
          </div>
        </IntercomProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sideBarData: state.dashboardReducer,
  barChartData: state.chartsDataReducer
});

const mapDispatchToProps = {
  getData,
  getPermissions,
  getTransData,
  filterBodyData,
  getFinanceData
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Generic)
);