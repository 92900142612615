/**Auth Action types */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const PASS_RESET = "PASS_RESET";
export const PASS_RESET_CONFM = "PASS_RESET_CONFM";
export const TOKEN_REFRESH = "TOKEN_REFRESH";
export const TOKEN_VERIFY = "TOKEN_VERIFY";
export const IS_ERROR = "IS_ERROR";

/** Dashboard Action types */
export const DASHBOARD = "DASHBOARD";
export const SIDE_BAR_MENU = "SIDE_BAR_MENU";
export const SIDE_BAR_MENU_TOGGLE = "SIDE_BAR_MENU_TOGGLE"; // for toggle the side bar class name

/** Charts Data Action types */
export const GET_DATA = "GET_DATA"; // testing data

/** transaction-Log Data Action types */
export const TRANSACTION_LOG_DATA = "TRANSACTION_LOG_DATA"; // testing data

export const FINANCE_DATA = "FINANCE_DATA"; // testing data

export const DOWNLOAD_PDF = "DOWNLOAD_PDF"; // testing data

export const FILTER_DATA = "FILTER_DATA"; // testing data

export const TRANSACTION_DETAILS = "TRANSACTION_DETAILS";

export const INTERFACE_PAYMENT = "INTERFACE_PAYMENT";

export const VIRTUAL_TERMINAL = "VIRTUAL_TERMINAL";

export const PAYMENT_METHODS = "PAYMENT_METHODS";

export const TX_DIAGNOSE = "TX_DIAGNOSE";

export const TRANSACTION_LOG_DOWNLOAD = "TRANSACTION_LOG_DOWNLOAD";

export const TRANSACTION_BREAK_DOWN_DOWNLOAD = "TRANSACTION_BREAK_DOWN_DOWNLOAD";

export const ADMIN_GET_SUBUSERS = "ADMIN_GET_SUBUSERS";

export const ADMIN_ADD_SUBUSER = "ADMIN_ADD_SUBUSER";

export const ADMIN_UPDATE_SUBUSER = "ADMIN_UPDATE_SUBUSER";

export const ADMIN_DELETE_SUBUSER = "ADMIN_DELETE_SUBUSER";

export const SET_PAYMENT_LINK = "PAYMENT_LINK_SET";

export const GET_PAYMENT_LINK = "PAYMENT_LINK_GET";

export const UPDATE_PAYMENT_LINK = "PAYMENT_LINK_UPDATE";

export const PAYMENT_LINK_LIST = "PAYMENT_LINK_LIST";

export const PAYMENT_LINK_EMAIL = "PAYMENT_LINK_EMAIL";

export const BOARD_PAYPAL_MERCHANT = "BOARD_PAYPAL_MERCHANT";

export const BOARD_PAYPAL_MERCHANT_STATUS = "BOARD_PAYPAL_MERCHANT_STATUS";

export const BOARD_PAYPAL_MERCHANT_DISABLE = "BOARD_PAYPAL_MERCHANT_DISABLE";

export const PARTNER_MERCHANTS_TOKEN = "PARTNER_MERCHANTS_TOKEN";

export const PARTNER_MERCHANTS_DATA = "PARTNER_MERCHANTS_DATA";

export const ECOMMERCE_FILES = "ECOMMERCE_FILES";

export const CHARGEBACK_ALERTS = "CHARGEBACK_ALERTS_DATA";

export const CHARGEBACK_ALERTS_FILTERS = "CHARGEBACK_ALERTS_FILTERS";

export const CHARGEBACK_PREVENTION_RULES = "CHARGEBACK_PREVENTION_RULES";

export const ALL_MERCHANTS = "ALL_MERCHANTS";

export const POS_TRANSACTION_LOG_DOWNLOAD = "POS_TRANSACTION_LOG_DOWNLOAD";

export const POS_TRANSACTION_BREAK_DOWN_DOWNLOAD = "POS_TRANSACTION_BREAK_DOWN_DOWNLOAD";

export const POS_TRANSACTION_LOG_DATA = "POS_TRANSACTION_LOG_DATA";

export const CHARGEBACK_RULES_OF_COMPANY = "CHARGEBACK_RULES_OF_COMPANY";

export const CHARGEBACK_RULES_EXPORT_DOWNLOAD =
    "CHARGEBACK_RULES_EXPORT_DOWNLOAD";

export const CHARGEBACK_ACCEPT = "CHARGEBACK_ACCEPT";

export const FETCH_CIFS_AND_MERCHANTS = "FETCH_CIFS_AND_MERCHANTS";
