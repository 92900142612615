import React from "react";
import { transactionTypeLabels } from "../../../../lib/constants/posTransactions";

const TxTypeCell = ({ value }) => {
  if (!value) return null;

  return (
    <div className="vertical-center">
      <p className="margin-0">{transactionTypeLabels[value] ?? value}</p>
    </div>
  );
};

export default TxTypeCell;
