import {faMobileAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from 'react';
import {ScaleLoader} from "react-spinners";

export const OTPForm = ({onSubmitOTPForm, isLoading}) => {
  const [otpCode, setOtpCode] = useState("");

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setOtpCode(value);
  }

  const handleSubmitOTPForm = (e) => {
    e.preventDefault && e.preventDefault();
    onSubmitOTPForm(otpCode)
  }

  return (
    <>
      <form
        onSubmit={handleSubmitOTPForm}
        name="otpForm"
        noValidate
      >
        <div className="form-group has-feedback">
          <input
            id="login_otp_input"
            type="text"
            className="form-control"
            placeholder="Enter your code"
            onChange={handleChange}
            value={otpCode}
          />
            <span
              className="form-control-feedback"
              style={{ pointerEvents: "auto" }}
              >
              <FontAwesomeIcon icon={faMobileAlt} />
            </span>
        </div>
      </form>
      <button
        type="submit"
        id="login_btn"
        className="btn btn-primary btn-lg btn-block login-btn"
        style={{ backgroundColor: "#55BD8B" }}
        onClick={handleSubmitOTPForm}
        >
        {isLoading === true ? (
          <ScaleLoader color={"white"} height={25} />
        ) : (
          "Continue"
        )}
      </button>
    </>
  );
};
