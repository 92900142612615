var countries = require("country-data").countries;
export const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const DataFormater = number => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else if (number % 1 !== 0) {
    return number.toFixed(2).toString();
  } else {
    return number.toString();
  }
};

export function renderUnit(title) {
  if (title.includes("ratio")) {
    return "%";
  } else if (title.includes("amount_eur") || title.includes("balance_eur")) {
    // "Settlement Amount History" has "balance" instead of "amount"
    return "€"; // �
  } else if (title.includes("amount_usd") || title.includes("balance_usd")) {
    return "$";
  }
}

export const figuresTopChecks = (key, meta_data) => {
  let reutrn_message = "-";

  if (key.includes("count")) {
    reutrn_message = DataFormater(meta_data[0]["tx_count"]);
    return reutrn_message;
  }
  else {
    return nFormatter(meta_data[0]["tx_volume"], 2) + "€";
  }

}
export const figuresTopChecksYesterday = (key, meta_data) => {
  let reutrn_message = "-";

  if (meta_data.length === 2) {
    if (key.includes("count")) {

      reutrn_message = "(" + meta_data[1]["date"] + "/" + DataFormater(meta_data[1]["tx_count"]) + ")"
    }

    else {
      reutrn_message = "(" + meta_data[1]["date"] + " - " + nFormatter(meta_data[1]["tx_volume"], 2) + "€)";
    }
  }

  return reutrn_message;

}

export const renderToolTip = (value, entry, payload) => {
  let chgb_error_messages = {
    "13": "N/A",
    "4808": "N/A",
    "10": "N/A",
    "10 ": "N/A",
    "13.7": "C Cons - Cancelled Merchandise/Services",
    "12.2": "C Proc - Incorrect Transaction Code",
    "12.6.1": "C Proc - Duplicate Processing/Paid by Other Means",
    "13.1": "C Cons - Merchandise/Services Not Received",
    "4837": "No Cardholder Authorization",
    "10.4": "A Fraud - Other Fraud-Card Absent Environment",
    "4863": "Cardholder Does Not Recognize - Potential Fraud or Non-Card",
    "4855": "Nonreceipt of Merchandise",
    "4841": "Canceled Recurring Transaction",

    "13.2": "C Cons - Cancelled Recurring",
    "4834": "Duplicate Processing",
    "4853": "Not as Described",
    "83": "Fraud - Card-Absent Environment",

    "6": "Card not present fraud",
    "1": "Stolen",
    "0": "Card reported lost",
    "00": "Card reported lost",
    "5": "Account takeover fraud",
    "2": "Never received issue",
    "4": "Counterfeit card fraud",
    "9": "Acquirer counterfeit",
    "3": "Fraudulent application",
    "7": "Multiple imprint fraud",
    "06": "Card not present fraud",
    B: "Account or credentials takeover",
    "04": "Counterfeit Card Fraud",

    "51": "Not sufficient funds",
    "05": "Do not Honour",
    "57": "Transaction not permitted on card",

    "14": "Invalid card number",
    "12": "Invalid transaction",
    "62": "Restricted card"
  };

  if (entry.length === 3 && countries[entry] && countries[entry].name) {
    entry = countries[entry].name;
  }

  if (payload.dataKey.includes("eur")) {
    let postfix = "€";
    if (payload.dataKey.includes("eur_ratio")) {
      entry = entry.replace("_ratio", "");
      entry = entry.replace("_count", "");
      entry = entry.replace("_eur", "");
      entry = entry.replace("_amount", "");
      entry = entry.replace("sales", "successful");
      entry = entry.replace("fail", "failed");
      postfix = "%";
    }

    if (chgb_error_messages[payload.payload.error_code] !== undefined) {
      entry = chgb_error_messages[payload.payload.error_code];
    }

    let newEurVal = Capitalize(entry) + ": " + nFormatter(value, 2) + postfix;
    if (!isNaN(entry)) {
      newEurVal = nFormatter(value, 2) + postfix;
    }
    return [`${newEurVal}`];
  } else if (payload.dataKey.includes("usd")) {
    let newUsdVal = nFormatter(value, 2) + "$";
    return [`${newUsdVal}`];
  } else if (payload.payload.error_code) {
    if (chgb_error_messages[payload.payload.error_code] !== undefined) {
      entry = chgb_error_messages[payload.payload.error_code];
      let newVal = Capitalize(entry) + ": " + nFormatter(value, 2);
      if (!isNaN(entry)) {
        newVal = nFormatter(value, 2);
      }
      // return [`${payload.value}`, `${payload.payload[meta_data.xVal]}`];
      return [`${newVal}`];
    } else {
      let newVal =
        "(" + payload.payload.error_code + ")" + nFormatter(value, 2);
      return [`${newVal}`];
    }
  } else if (payload.dataKey.includes("ratio")) {
    if (!entry.includes("fraud") && !entry.includes("chargeback")) {
      entry = entry.replace("_ratio", "");
      entry = entry.replace("_count", "");
      entry = entry.replace("sales", "successful");
      entry = entry.replace("fail", "failed");
    }
    let newUsdVal = Capitalize(entry) + ": " + nFormatter(value, 2) + "%";
    return [`${newUsdVal}`];
  } else {
    if (chgb_error_messages[payload.payload.error_code] !== undefined) {
      entry = chgb_error_messages[payload.payload.error_code];
    }
    let newVal = Capitalize(entry) + ": " + nFormatter(value, 2);
    if (!isNaN(entry)) {
      newVal = nFormatter(value, 2);
    }
    // return [`${payload.value}`, `${payload.payload[meta_data.xVal]}`];
    return [`${newVal}`];
  }
};
export const renderLabelToolTip = value => {
  if (value.length === 3 && countries[value] && countries[value].name) {
    value = countries[value].name;
  }

  return value;
};
export const getMinWidthForColumns = key => {
  if (key === "company") {
    return 180;
  } else if (key.includes("count")) {
    return 90;
  } else if (key.includes("ratio")) {
    return 60;
  } else if (key.includes("email")) {
    return 180;
  } else if (key === "cardholder_name") {
    return 180;
  } else if (key === "error") {
    return 250;
  } else {
    return 0;
  }
};
export const Capitalize = str => {
  str = str.toString();

  str = str.replace("v_", "");
  str = str.replace("m_", "");
  if (str.startsWith("a_")) {
    str = str.replace("a_", "");
  }
  if (str.startsWith("p_")) {
    str = str.replace("p_", "");
  }

  str = str.replace("_percent", "%");
  var frags = str.split("_");
  var i = 0;
  for (i = 0; i < frags.length; i++) {
    if (frags[i].length === 2 || frags[i].length === 3  ||  frags[i].toLowerCase() === 'vamp') {
      frags[i] = frags[i].toUpperCase();
    } else {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
  }
  return frags.join(" ");
};

export const renderTransDetails = transaction_id => {
  let windowName = transaction_id;
  window.open(
    `/generic/transaction-details?${transaction_id}`,
    windowName,
    "height=600px,width=900px"
  );
};

export const renderChargebackPreventionRules = () => {
  let windowName = "chargeback-prevention-rules";
  window.open(
    `/generic/chargeback-prevention-rules`,
    windowName,
    "height=600px,width=900px"
  );
};

export const renderPayLinkDetails = linkid => {
  window.location.replace(
    `/generic/payment-link-details?${linkid}`
  );
};

export function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const sortDataForTables = (a, b) => {
  const regex = /%/;
    if(regex.test(a)) {
      a = a.replace(regex, '');
    }
    if(regex.test(b)) {
      b = b.replace(regex, '');
    }
    return Number(a) - Number(b);
}