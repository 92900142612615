import React, { useState, useEffect } from "react";
import SingleSelect from "../../general/core/SingleSelect";
import SingleInput from "../../general/core/SingleInput";
import moment from "moment";

const LinkDetailsForm = ({ updateForm }) => {
  //let _isMounted = false;
  const LIFESPAN_DATE = new Date(Date.parse(new Date()));
  const HOURS_24 = "24";
  const HOURS_DEFAULT = { label: "24 hours", value: "24" };
  const HOURS_ARRAY = [
    {label: "6 hours", value: "6"},
    {label: "12 hours", value: "12"},
    {label: "24 hours", value: "24"},
    {label: "36 hours", value: "36"},
    {label: "2 Days", value: "48"},
    {label: "3 Days", value: "72"},
    {label: "4 Days", value: "96"},
    {label: "5 Days", value: "120"},
    {label: "6 Days", value: "144"},
    {label: "7 Days", value: "168"}
  ];

  const [date] = useState(new Date());
  const [dayname, setDayname] = useState(moment(LIFESPAN_DATE).format("dddd"));
  const [expiryDay, setExpiryDay] = useState(moment(LIFESPAN_DATE).format("DD/MM/YYYY"));
  const [expiryTime, setExpiryTime] = useState(moment(LIFESPAN_DATE).format("HH:mm"));

  const computeDateTime = hours => {
    let lifespanDate = new Date();
    lifespanDate.setHours(date.getHours() + parseInt(hours));
    setDayname(moment(lifespanDate).format("dddd"));
    setExpiryDay(moment(lifespanDate).format("DD/MM/YYYY"));
    setExpiryTime(moment(lifespanDate).format("HH:mm"));
  }

  const handleSelectDuration = event => {
    updateForm(event.name, event.value);
    computeDateTime(event.value);
  }

  const handleInputChange = event => {
    if (event.target) {
      updateForm(event.target.name, event.target.value);
    } else {
      updateForm(event.name, event.value);
    }
  }

  useEffect(() => {
    computeDateTime(HOURS_24);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

	return (
		<div>
      <div style={{width: "100%", borderBottom: "1px solid #E2E8F0", fontSize: "20px", fontWeight: "bold", color:"#475569", marginBottom:"32px" }}>
        Link details
      </div>

      <SingleSelect
        id="merchantIDSelection"
        placeholder="Select duration"
        width="100%"
        label="Valid for"
        name="lifespanHours"
        options={HOURS_ARRAY}
        defaultValue={HOURS_DEFAULT}
        marginTop="32px"
        onChange={handleSelectDuration}
      />

      <SingleInput
        id="merchantIDSelection"
        width="100%"
        label="Expires on"
        autoComplete="off"
        marginTop="4px"
        disabled={true}
        backgroundColor="#eff0f1"
        value={expiryDay + " " + dayname + " @ " + expiryTime}
      />

      <SingleInput
        id="merchantIDSelection"
        name="email"
        placeholder="Customer email"
        width="100%"
        label="Email"
        autoComplete="off"
        marginTop="4px"
        backgroundColor="#eff0f1"
        onChange={handleInputChange}
        data-matomo-mask
      />
    </div>
	);
};

export default LinkDetailsForm;