import * as actionTypes from "../../lib/constants/actionTypes";

const initialState = {
  data: null,
  permission: null,
  getChartData: null,
  isSidebarToggled: false,
  cifsAndMerchants: null
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.SIDE_BAR_MENU:
      return {
        ...state,
        data: action.payload,
        permission: action.payload.permissions
      };
    case actionTypes.SIDE_BAR_MENU_TOGGLE:
      return {
        ...state,
        isSidebarToggled: action.payload.data,
      };
      case actionTypes.FETCH_CIFS_AND_MERCHANTS:
        console.log("action.payload", action.payload)
        return {
          ...state,
          cifsAndMerchants: action.payload,
        };
    default:
      return state;
  }
};

export default dashboardReducer;